import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage, storageRefs } from '../../config/firebase';
import { getDatabase, ref, push, set, child, onValue, update } from "firebase/database";
import { getDownloadURL, uploadBytesResumable, uploadBytes, listAll, list,} from "firebase/storage";
import './viewProduct.css';
import { onAuthStateChanged, signOut } from "firebase/auth";

function Category() {

    const [datas, setDatas] = useState([]);
    const [term, setTerm] = useState("");

    const [categoryId, setCategoryId] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [progress, setProgress] = useState(0);
    const [categoryImage, setCategoryImage] = useState(""); 

    const [gst, setGst] = useState(""); 

    const [edit, setEdit] = useState(false); 

    const navigate = useNavigate();

    const [adminEmail, setAdminEmail] = useState("");
    const [adminName, setAdminName] = useState("");

    const [submitCheck, setSubmitCheck] = useState(false);
  
    useEffect(() => { 
      const getUserData = async () => {
        await onAuthStateChanged(auth, (user) => { 
          if(user){
            onValue(ref(database, '/admin/' + auth.currentUser.uid), (snap) => {
            setAdminName(snap.val().adminName);
            setAdminEmail(snap.val().adminEmail);
        }); 
              }
              else {
                window.location.href = '/'; 
              }
            })
       }
         getUserData(); 
    
    },[]);

      useEffect(() => { 
    const getSideData = async () => {
      let sidebar = document.querySelector(".sidebar");
      let sidebarBtn = document.querySelector(".sidebarBtn");
      sidebarBtn.onclick = function() {
        sidebar.classList.toggle("active");
        if(sidebar.classList.contains("active")){
        sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
      }else
        sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
      }
    }
     getSideData(); 

},[]);   

    useEffect(() => { 
        const getUserData = async () => {

            const starCountRef = ref(database, 'category/');
            onValue(starCountRef, async snapshot => {
                let returnArray = [];
             await snapshot.forEach(element => {
                var item = {};
                item.categoryId = element.val().categoryId;
                item.categoryImage = element.val().categoryImage;
                item.categoryName = element.val().categoryName;
                item.gst = element.val().gst;
                returnArray.push(item);
            console.log(returnArray);
            setDatas(returnArray);
              });

            });         
         }
           getUserData(); 

    },[]); 

    let handleImageChange = (e) => {
      e.preventDefault();
      if(categoryName == ""){
        alert("Please enter category name.");
        document.getElementById("filesCategory").value = "";
      }
      else{
      let file = e.target.files[0];              
       let name = moment().unix()+file.name;
       let extFile = name.split('.').pop();
       if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
       const storageRef = storageRefs(storage, `category/${name}`);
       const uploadTask = uploadBytesResumable(storageRef, file);
   
       uploadTask.on("state_changed",
         (snapshot) => {
           const progresspercent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
           setProgress(progresspercent);
         },
         (error) => {
           console.log(error);
         },
         () => {
           getDownloadURL(storageRef).then((downloadURL) => {
            console.log(downloadURL);
            setCategoryImage(downloadURL);
           });
         }
       );
      }
      else{
    alert('Please select jpg, jpeg and png file only.');
    document.getElementById("filesCategory").value = "";
      }

        }
}

let submit = e => {
  e.preventDefault();
  setSubmitCheck(true);
  if(categoryName == ""){
    alert("Please enter category name.");
    setSubmitCheck(false);
  }
  else if(gst == ""){
    alert("Please enter gst.");
    setSubmitCheck(false);
  }
else{
  const newPostKey = push(child(ref(database), 'category')).key;

  const postData = {
    categoryImage: categoryImage,
    categoryName: categoryName,
    gst: gst,
    categoryId: newPostKey
  };

  const updates = {};
  updates['/category/' + newPostKey] = postData;

  return update(ref(database), updates).then(() => {
    alert('Category is created successfully.');
    setSubmitCheck(false);
    setCategoryName("");
    setProgress(0);
    setCategoryImage(""); 
    setGst("");
      });
}
}

let reset = e => {
  e.preventDefault();
  window.location.reload();
}

let logOut = e => {
  signOut(auth);
   window.location.href = '/';
   }

   let categoryEdit = (data) => {
setEdit(true);
setCategoryId(data);
let dataa = datas.find(x=>x.categoryId == data);
setCategoryName(dataa.categoryName);
setCategoryImage(dataa.categoryImage);
setGst(dataa.gst);
   }

   let submitEdit = e => {
    e.preventDefault();
    setSubmitCheck(true);
    if(categoryName == ""){
      alert("Please enter category name.");
      setSubmitCheck(false);
    }
  else{
    update(ref(database, 'category/' + categoryId), {
      categoryName: categoryName,
      categoryImage: categoryImage,
      gst: gst
    }).then(() => {
      alert('Category is updated successfully.');
      setEdit(false);
      setSubmitCheck(false);
      setCategoryName("");
      setProgress(0);
      setCategoryImage(""); 
      setCategoryId("");
      setGst("");
        });
  }
  }

    return (
      <section>
      <div className="sidebar">
     
      <div className="logo-details">&nbsp;
      <img src={require("./images/logoF.png")} style={{width: '50px', height: '50px'}} alt=""/> &nbsp;
      <img src={require("./images/elge.png")} style={{width: '150px', height: '80px'}} alt=""/>
      </div>
        <ul className="nav-links">
          <li>
          <Link to={"/dashboard"}>
            <a>
              <i className='bx bx-grid-alt' ></i>
              <span className="links_name">Dashboard</span>
            </a>
            </Link>
          </li>
          <li>
            <a className="active">
              <i className='bx bx-align-justify' ></i>
              <span className="links_name">Manage Category</span>
            </a>
          </li>
          <li>
          <Link to={"/brand"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Brand</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/pack"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Pack</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageAgents"}>
            <a>
              <i className='bx bx-user-pin' ></i>
              <span className="links_name">Manage Agents</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/viewProduct"}>
            <a>
              <i className='bx bx-box' ></i>
              <span className="links_name">Manage Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/createProduct"}>
            <a>
            <i className='bx bx-pie-chart-alt-2' ></i>
              <span className="links_name">Create Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageCustomer"}>
            <a >
              <i className='bx bx-user' ></i>
              <span className="links_name">Manage Customer</span>
            </a>
            </Link>
          </li>
          <li>     
          <Link to={"/createCustomer"}>   
            <a>
            <i className='bx bx-user-plus' ></i>
              <span className="links_name">Create Customer</span>
            </a> 
            </Link>         
          </li>
          <li>
          <Link to={"/orders"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Orders</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/settings"}>
            <a>
              <i className='bx bx-cog' ></i>
              <span className="links_name">Settings</span>
            </a>
            </Link>
          </li>
          <li className="log_out">
          <a onClick={logOut}>
              <i className='bx bx-log-out'></i>
              <span className="links_name">Log out</span>
            </a>
          </li>
        </ul>
    </div>
    <section className="home-section">
      <nav>
        <div className="sidebar-button">
          <i className='bx bx-menu sidebarBtn'></i>
          <span className="dashboard">Manage Category</span>
        </div>
        <div className="profile-details">
        <img src={require("./images/male.png")} style={{width: '50px', height: '50px'}} alt=""/>
          <span className="admin_name">Admin</span>
          <i className='bx bx-chevron-down' ></i>
        </div>
      </nav>
  
      <div className="home-content">
      <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
      <section style={{overflow: 'auto', minHeight: '100%'}}>

<div className="admincreatedocview">

{edit == false?
      <div className="container">
          <div className="superadmincreatedoc_details" 
          style={{marginTop:'10px', marginBottom:'20px', backgroundColor:'white', boxShadow:' 0px 0 5px rgba(0, 0, 0, 0.3)', borderRadius:'10px',
         }}>

            <div className="row" style={{display:'flex', justifyContent:'center', padding:'30px 50px'}}>
            
                <div className="superadmincreatedoc_info">

                    <div className="row">

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>CATEGORY NAME </label>
       
      <input type="text" className="form-control" id="categoryName" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      placeholder="Category Name" name="categoryName" value={categoryName} 
      onChange={(event) => { setCategoryName( event.target.value ); }} required/>
      
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>COVER IMAGE</label>
       
      <input type="file" className="form-control" id="filesCategory" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      name="filesCategory" 
      onChange={handleImageChange} required/>
      
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>GST (%)</label>
       
                        <input type="text" className="form-control" id="gst" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      placeholder="GST" name="gst" value={gst} 
      onChange={(event) => { setGst( event.target.value ); }} required/>
      
      </div>

      <hr/>              
    </div>

    <div className="superadmincreatedoc_savebtn" style={{textAlign:'center', marginTop:'10px'}}>
    {submitCheck == false?
                                  <>
                   <button className="btn btn-primary" style={{borderRadius:'5px'}} onClick={submit}>Save</button>
                        <button className="btn btn-secondary ml-2" style={{borderRadius:'5px'}} onClick={reset}>Reset</button>
                        </>
                        :
                        <center>
                        <div className="loader"></div>
                        </center>
              }
                   </div>

      </div>
    </div>

    </div>
    </div>

:
<div className="container">
<div className="superadmincreatedoc_details" 
style={{marginTop:'10px', marginBottom:'20px', backgroundColor:'white', boxShadow:' 0px 0 5px rgba(0, 0, 0, 0.3)', borderRadius:'10px',
}}>

  <div className="row" style={{display:'flex', justifyContent:'center', padding:'30px 50px'}}>
  
      <div className="superadmincreatedoc_info">

          <div className="row">

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <label style={{fontWeight:'600', fontSize:'14px'}}>CATEGORY NAME </label>

<input type="text" className="form-control" id="categoryName" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
placeholder="Category Name" name="categoryName" value={categoryName} 
onChange={(event) => { setCategoryName( event.target.value ); }} required/>

</div>

<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <label style={{fontWeight:'600', fontSize:'14px'}}>COVER IMAGE</label>

<input type="file" className="form-control" id="files" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
name="files" 
onChange={handleImageChange} required/>

</div>

<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <label style={{fontWeight:'600', fontSize:'14px'}}>GST (%)</label>

              <input type="text" className="form-control" id="gst" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
placeholder="GST" name="gst" value={gst} 
onChange={(event) => { setGst( event.target.value ); }} required/>

</div>

<hr/>              
</div>

<div className="superadmincreatedoc_savebtn" style={{textAlign:'center', marginTop:'10px'}}>
{submitCheck == false?
                                  <>
         <button className="btn btn-primary" style={{borderRadius:'5px'}} onClick={submitEdit}>Update</button>
              </>
                        :
                        <center>
                        <div className="loader"></div>
                        </center>
              }    
         </div>

</div>
</div>

</div>
</div>
}

    </div> 
        </section> 
        </div> 


        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
      <div className='search_result_list my-2'>

            <div className="table-responsive mb-2" style={{borderRadius: '10px',border:'0.5px solid #ccc',borderCollapse: 'collapse'}}>
         <table className='table table-borderless mb-0' style={{border:'none', background: "white"}}>
         <thead className="text-center" style={{backgroundColor:'#e7f4fe', color:'grey'}}>
           <tr>
             <th style={{fontSize: '16px'}}>Image</th>
             <th style={{fontSize: '16px'}}>Category Name</th>
             <th style={{fontSize: '16px'}}>GST</th>
             <th style={{fontSize: '16px'}}>Action</th>
           </tr>
           </thead>
           <tbody>
           {datas.length == 0?
            <tr>
            <td style={{verticalAlign: 'middle', fontSize: '14px'}}></td>
            <td style={{verticalAlign: 'middle', fontSize: '14px'}}></td>
            <td style={{verticalAlign: 'middle', fontSize: '14px'}}>No data</td>
            <td style={{verticalAlign: 'middle', fontSize: '14px'}}></td>
          </tr>
          :
          <>
            {datas.map((persons,index) =>
      
           <tr key={index}>
             <td> <img src={persons.categoryImage} style={{borderRadius:'5px',width:'50%', height: '7vh', border:'2px solid #ccc', objectFit: 'contain'}}/></td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.categoryName}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.gst}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}><i className="bx bx-edit" style={{fontSize: '24px'}} aria-hidden="true" onClick={() => categoryEdit(persons.categoryId)}></i> </td>
           </tr>
          )}
          </>
}
</tbody>
</table>
       </div>
       </div>
          </div>
          </div>
 </div>
        </section> 
</section>
        );

    }
    export default Category;