import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage, storageRefs } from '../../config/firebase';
import { getDatabase, ref, push, set, onValue } from "firebase/database";
import { onAuthStateChanged, signOut } from "firebase/auth";
import './viewProduct.css';

function ManageCustomer() {

    const [datas, setDatas] = useState([]);
    const [term, setTerm] = useState("");

      const navigate = useNavigate();

      const [adminEmail, setAdminEmail] = useState("");
      const [adminName, setAdminName] = useState("");
    
      useEffect(() => { 
        const getUserData = async () => {
          await onAuthStateChanged(auth, (user) => { 
            if(user){
              onValue(ref(database, '/admin/' + auth.currentUser.uid), (snap) => {
              setAdminName(snap.val().adminName);
              setAdminEmail(snap.val().adminEmail);
          }); 
                }
                else {
                  window.location.href = '/'; 
                }
              })
         }
           getUserData(); 
      
      },[]);

      useEffect(() => { 
        const getSideData = async () => {
          let sidebar = document.querySelector(".sidebar");
          let sidebarBtn = document.querySelector(".sidebarBtn");
          sidebarBtn.onclick = function() {
            sidebar.classList.toggle("active");
            if(sidebar.classList.contains("active")){
            sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
          }else
            sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
          }
        }
         getSideData(); 
    
    },[]); 

    useEffect(() => { 
        const getUserData = async () => {

            const starCountRef = ref(database, 'customer/');
            onValue(starCountRef, async snapshot => {
                let returnArray = [];
             await snapshot.forEach(element => {
                var item = {};
                item.shopName = element.val().shopName;
                item.shopImage = element.val().shopImage;
                item.customerId = element.val().customerId;
                item.customerType = element.val().customerType;
                item.customerName = element.val().customerName;
                item.addressLine = element.val().addressLine;
                item.addressLine1 = element.val().addressLine1;
                item.locality = element.val().locality;
                item.city = element.val().city;
                item.pinCode = element.val().pinCode;
                item.commAddressLine = element.val().commAddressLine;
                item.commAddressLine1 = element.val().commAddressLine1;
                item.commCity = element.val().commCity;
                item.commPinCode = element.val().commPinCode;
                item.gstNumber = element.val().gstNumber;
                item.state = element.val().state;
                item.status = element.val().status;
                item.stateCode = element.val().stateCode;
                item.profileImage = element.val().profileImage;
                item.phoneNumber = element.val().phoneNumber;
                item.landlineNumber = element.val().landlineNumber;
                returnArray.push(item);
              });
              console.log(returnArray);
              setDatas(returnArray);
            });         
         }
           getUserData(); 

    },[]); 

    let customerEdit = (data) => {
      navigate('/editCustomer', {
        state: {
          customerIdPass: data,
        }
      });
    }

    let customerOrder = (data) => {
      navigate('/customerOrder', {
        state: {
          customerIdPass: data,
        }
      });
    }

    let logOut = e => {
      signOut(auth);
       window.location.href = '/';
       }

    return (
      <section>
      <div className="sidebar">
     
      <div className="logo-details">&nbsp;
      <img src={require("./images/logoF.png")} style={{width: '50px', height: '50px'}} alt=""/> &nbsp;
      <img src={require("./images/elge.png")} style={{width: '150px', height: '80px'}} alt=""/>
      </div>
        <ul className="nav-links">
          <li>
          <Link to={"/dashboard"}>
            <a>
              <i className='bx bx-grid-alt' ></i>
              <span className="links_name">Dashboard</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/category"}>
            <a>
              <i className='bx bx-align-justify' ></i>
              <span className="links_name">Manage Category</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/brand"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Brand</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/pack"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Pack</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageAgents"}>
            <a>
              <i className='bx bx-user-pin' ></i>
              <span className="links_name">Manage Agents</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/viewProduct"}>
            <a>
              <i className='bx bx-box' ></i>
              <span className="links_name">Manage Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/createProduct"}>
            <a>
            <i className='bx bx-pie-chart-alt-2' ></i>
              <span className="links_name">Create Product</span>
            </a>
            </Link>
          </li>
          <li>
            <a className="active">
              <i className='bx bx-user' ></i>
              <span className="links_name">Manage Customer</span>
            </a>
          </li>
          <li>
          <Link to={"/createCustomer"}>
            <a>
            <i className='bx bx-user-plus' ></i>
              <span className="links_name">Create Customer</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/orders"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Orders</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/settings"}>
            <a>
              <i className='bx bx-cog' ></i>
              <span className="links_name">Settings</span>
            </a>
            </Link>
          </li>
          <li className="log_out">
          <a onClick={logOut}>
              <i className='bx bx-log-out'></i>
              <span className="links_name">Log out</span>
            </a>
          </li>
        </ul>
    </div>
    <section className="home-section">
      <nav>
        <div className="sidebar-button">
          <i className='bx bx-menu sidebarBtn'></i>
          <span className="dashboard">Manage Customer</span>
        </div>
        <div className="search-box">
          <input type="text" placeholder="Search by Shop Name & GST & City & Locality & Status..." onChange={(event) => { setTerm( event.target.value ); }} value={term}/>
          <i className='bx bx-search' ></i>
        </div>
        <div className="profile-details">
        <img src={require("./images/male.png")} style={{width: '50px', height: '50px'}} alt=""/>
          <span className="admin_name">Admin</span>
          <i className='bx bx-chevron-down' ></i>
        </div>
      </nav>
  
      <div className="home-content">

      <div className='search_result_list my-2'>
            <div className="row">
            {datas.filter(x => x.shopName.toLowerCase().includes(term.toLowerCase()) || x.gstNumber.toLowerCase().includes(term.toLowerCase()) || x.city.toLowerCase().includes(term.toLowerCase()) || x.locality.toLowerCase().includes(term.toLowerCase()) || x.status.toLowerCase().includes(term.toLowerCase())).map((persons,index) =>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 my-2" key={index}>
          <div className="search_result_box" style={{backgroundColor:'white', padding:'15px', boxShadow:'1px 1px 3px rgba(0, 93, 214, 0.9)'}}>
            <div className="row">
              <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 search_result_doctoricon"> 
              <center>
                        {persons.profileImage == ""?
               <div style={{borderRadius:'7px',width:'100%', height: '16vh', border:'1px solid #ccc', marginTop: '5px', marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
               <h6 style={{flex: 1, alignSelf: 'center'}}>No Image</h6>
                </div>  
       :

       <img src={persons.profileImage} style={{borderRadius:'7px',width:'100%', height: '16vh', border:'2px solid #ccc', objectFit: 'fill'}}/>
}
           </center>
             
                   
              <center style={{paddingTop: '10px'}}>  <button type="button" data-dismiss="modal" className="btn btn-danger" onClick={() => customerEdit(persons.customerId)} style={{color: 'white'}}>Edit</button>
              </center> 
                   </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0">
                <div className="search_result_doctorName">
                  <center>
                <span style={{fontSize:'16px', color:'#1e40e9', fontWeight: 300, cursor: 'pointer'}}><b>{persons.shopName}</b></span>
               
                </center>
                 </div>
                <div className="search_result_designation">
                <center>
                <span style={{fontSize:'14px', color:'#666', cursor: 'pointer'}}>{persons.customerName} ({persons.customerType})</span>
                </center> 
                </div>
                <div className="hospital_details">

                {persons.status == "Active"?
<>
<span style={{fontSize:'14px', color:'#666', cursor: 'pointer'}}>Status: <span style={{fontSize:'14px', color:'green', fontWeight: 400}}>{persons.status}</span></span><br/>
</>
:
<>
<span style={{fontSize:'14px', color:'#666', cursor: 'pointer'}}>Status: <span style={{fontSize:'14px', color:'red', fontWeight: 400}}>{persons.status}</span></span><br/>
</>
}

                  <span style={{fontSize:'14px', color:'#666', cursor: 'pointer'}}>GST No: <span style={{fontSize:'14px', color:'#1e40e9', fontWeight: 400}}>{persons.gstNumber}</span></span><br/>
                  <span style={{fontSize:'14px', color:'#666', cursor: 'pointer'}}>Ph.No: <span style={{fontSize:'14px', color:'#1e40e9', fontWeight: 400}}>{persons.phoneNumber.substring(3)}</span></span><br/>
                  {persons.landlineNumber == ""?
                  null
                  :
                  <>
                  <span style={{fontSize:'14px', color:'#666', cursor: 'pointer'}}>LL.No: <span style={{fontSize:'14px', color:'#1e40e9', fontWeight: 400}}>{persons.landlineNumber}</span></span><br/>
                  </>
} 
                  <span style={{fontSize:'14px', color:'#666', cursor: 'pointer'}}>Shipping Address: <span style={{fontSize:'14px', color:'#1e40e9', fontWeight: 400}}>{persons.addressLine}, {persons.addressLine1}, {persons.locality}, {persons.city}, {persons.state}, {persons.pinCode}</span></span><br/>
                  <span style={{fontSize:'14px', color:'#666', cursor: 'pointer'}}>Billing Address: <span style={{fontSize:'14px', color:'#1e40e9', fontWeight: 400}}>{persons.commAddressLine == ""?
                  <span> Nil</span>
                  :
                <span>
                  {persons.commAddressLine}, {persons.commAddressLine1}, {persons.commCity}, {persons.state}, {persons.commPinCode}
               </span>
                 }</span></span><br/>
                                  </div>

              </div>

              <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 search_result_doctoricon">   
              <center>
                        {persons.shopImage == ""?
               <div style={{borderRadius:'7px',width:'100%', height: '16vh', border:'1px solid #ccc', marginTop: '5px', marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
               <h6 style={{flex: 1, alignSelf: 'center'}}>No Image</h6>
                </div>  
       :

       <img src={persons.shopImage} style={{borderRadius:'7px',width:'100%', height: '16vh', border:'2px solid #ccc', objectFit: 'fill'}}/>
}
           </center>       
              
           <center style={{paddingTop: '10px'}}> <button type="button" data-dismiss="modal" className="btn btn-primary" onClick={() => customerOrder(persons.customerId)}>Orders</button>
           </center> 
         </div>

                </div>

            </div>
          </div>
          )}
        </div>

          </div>
          </div>

        </section> 
</section>
        );

    }
    export default ManageCustomer;