import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage, storageRefs, db } from '../../config/firebase';
import { getDatabase, ref, push, set, onValue } from "firebase/database";
import { getDownloadURL, uploadBytesResumable, uploadBytes, listAll, list,} from "firebase/storage";
import { doc, setDoc, Timestamp } from "firebase/firestore"; 
import { onAuthStateChanged, signOut } from "firebase/auth";

const d = moment().unix();

function CreateProduct() {

  const [productId, setProductId] = useState(d);
  const [categoryId, setCategoryId] = useState("");
    const [category, setCategory] = useState("");
    const [brandName, setBrandName] = useState("");
    const [itemName, setItemName] = useState("");
    const [size, setSize] = useState("");
    const [pack, setPack] = useState("");
    const [composition, setComposition] = useState("");
    const [yarnCount, setYarnCount] = useState("");
    const [priceWholesaler, setPriceWholesaler] = useState("");
    const [priceRetailer, setPriceRetailer] = useState("");
    const [pricePer, setPricePer] = useState("");
    const [progress, setProgress] = useState(0);
    const [coverImage, setCoverImage] = useState("");   
    const [sliderImage1, setSliderImage1] = useState(""); 
    const [sliderImage2, setSliderImage2] = useState(""); 
    const [sliderImage3, setSliderImage3] = useState("");  
    const [sliderImage4, setSliderImage4] = useState("");  
    const [sliderImage5, setSliderImage5] = useState("");  
    const [productDescription, setProductDescription] = useState("");
    const [status, setStatus] = useState("Active");

    const [promotionalFeatures, setPromotionalFeatures] = useState("No");

    const [categoryDatas, setCategoryDatas] = useState([]);
    const [brandDatas, setBrandDatas] = useState([]);

    const [packDatas, setPackDatas] = useState([]);

    const [adminEmail, setAdminEmail] = useState("");
    const [adminName, setAdminName] = useState("");

    const [inStocks, setInStocks] = useState("");

    const [submitCheck, setSubmitCheck] = useState(false);

    useEffect(() => { 
      const getUserData1 = async () => {
  
        const starCountRef = ref(database, 'category/');
        onValue(starCountRef, async snapshot => {
          let returnArray1 = [];
          if (snapshot.exists()) {
          snapshot.forEach((element) => {
            var item = {};
            item.categoryId = element.val().categoryId;
            item.categoryName = element.val().categoryName;
            returnArray1.push(item);
          });
          console.log(returnArray1);
  setCategoryDatas(returnArray1);
    }
    else {
    }
        });         
     }
       getUserData1(); 
  
  },[]); 
  
    useEffect(() => { 
      const getUserData = async () => {
        await onAuthStateChanged(auth, (user) => { 
          if(user){
            onValue(ref(database, '/admin/' + auth.currentUser.uid), (snap) => {
            setAdminName(snap.val().adminName);
            setAdminEmail(snap.val().adminEmail);
        }); 
              }
              else {
                window.location.href = '/'; 
              }
            })
       }
         getUserData(); 
    
    },[]);

    useEffect(() => { 
      const getSideData = async () => {
        let sidebar = document.querySelector(".sidebar");
        let sidebarBtn = document.querySelector(".sidebarBtn");
        sidebarBtn.onclick = function() {
          sidebar.classList.toggle("active");
          if(sidebar.classList.contains("active")){
          sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
        }else
          sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
        }
      }
       getSideData(); 
  
  },[]); 

  useEffect(() => { 
    const getUserData1 = async () => {

        const starCountRef = ref(database, 'brand/');
        onValue(starCountRef, async snapshot => {
            let returnArray = [];
         await snapshot.forEach(element => {
            var item = {};
            item.brandName = element.val().brandName;
            item.categoryName = element.val().categoryName;
            item.brandId = element.val().brandId;
            item.brandImage = element.val().brandImage;
            returnArray.push(item);
        console.log(returnArray);
        setBrandDatas(returnArray);
          });

        });         
     }
       getUserData1(); 

},[]); 

useEffect(() => { 
  const getUserData2 = async () => {

      const starCountRef = ref(database, 'pack/');
      onValue(starCountRef, async snapshot => {
          let returnArray = [];
       await snapshot.forEach(element => {
          var item = {};
          item.packName = element.val().packName;
          item.categoryName = element.val().categoryName;
          item.packId = element.val().packId;
          item.pricePer = element.val().pricePer;
          returnArray.push(item);
     // console.log(returnArray);
      setPackDatas(returnArray);
        });

      });         
   }
     getUserData2(); 

},[]); 

    let handleImageChange = (e) => {
      e.preventDefault();
      if(category == ""){
        alert("Please Select Category.");
        document.getElementById("filesProduct").value = "";
      }
      else if(brandName == ""){
        alert("Please Select Brand Name.");
        document.getElementById("filesProduct").value = "";
      }
      else{
      let file = e.target.files[0];              
       let name = moment().unix()+file.name;
     let extFile = name.split('.').pop();
   if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
       const storageRef = storageRefs(storage, `products/${name}`);
       const uploadTask = uploadBytesResumable(storageRef, file);
   
       uploadTask.on("state_changed",
         (snapshot) => {
           const progresspercent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
           setProgress(progresspercent);
         },
         (error) => {
           console.log(error);
         },
         () => {
           getDownloadURL(storageRef).then((downloadURL) => {
            console.log(downloadURL);
             setCoverImage(downloadURL)
           });
         }
       );
         }
  else{
alert('Please select jpg, jpeg and png file only.');
document.getElementById("filesProduct").value = "";
  }
        }
}

let handleImageChange1 = (e) => {
  e.preventDefault();
  let file = e.target.files[0];              
   let name = moment().unix()+file.name;
     let extFile = name.split('.').pop();
   if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
   const storageRef = storageRefs(storage, `productsImages/${name}`);
   const uploadTask = uploadBytesResumable(storageRef, file);

   uploadTask.on("state_changed",
     (snapshot) => {
       const progresspercent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
       setProgress(progresspercent);
     },
     (error) => {
       console.log(error);
     },
     () => {
       getDownloadURL(storageRef).then((downloadURL) => {
        console.log(downloadURL);
         setSliderImage1(downloadURL)
       });
     }
   );
            }
  else{
alert('Please select jpg, jpeg and png file only.');
document.getElementById("filesProduct1").value = "";
  }
}

let handleImageChange2 = (e) => {
  e.preventDefault();
  let file = e.target.files[0];              
   let name = moment().unix()+file.name;
     let extFile = name.split('.').pop();
   if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
   const storageRef = storageRefs(storage, `productsImages/${name}`);
   const uploadTask = uploadBytesResumable(storageRef, file);

   uploadTask.on("state_changed",
     (snapshot) => {
       const progresspercent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
       setProgress(progresspercent);
     },
     (error) => {
       console.log(error);
     },
     () => {
       getDownloadURL(storageRef).then((downloadURL) => {
        console.log(downloadURL);
         setSliderImage2(downloadURL)
       });
     }
   );
            }
  else{
alert('Please select jpg, jpeg and png file only.');
document.getElementById("filesProduct2").value = "";
  }
}

let handleImageChange3 = (e) => {
  e.preventDefault();
  let file = e.target.files[0];              
   let name = moment().unix()+file.name;
     let extFile = name.split('.').pop();
   if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
   const storageRef = storageRefs(storage, `productsImages/${name}`);
   const uploadTask = uploadBytesResumable(storageRef, file);

   uploadTask.on("state_changed",
     (snapshot) => {
       const progresspercent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
       setProgress(progresspercent);
     },
     (error) => {
       console.log(error);
     },
     () => {
       getDownloadURL(storageRef).then((downloadURL) => {
        console.log(downloadURL);
         setSliderImage3(downloadURL)
       });
     }
   );
            }
  else{
alert('Please select jpg, jpeg and png file only.');
document.getElementById("filesProduct3").value = "";
  }
}

let handleImageChange4 = (e) => {
  e.preventDefault();
  let file = e.target.files[0];              
   let name = moment().unix()+file.name;
     let extFile = name.split('.').pop();
   if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
   const storageRef = storageRefs(storage, `productsImages/${name}`);
   const uploadTask = uploadBytesResumable(storageRef, file);

   uploadTask.on("state_changed",
     (snapshot) => {
       const progresspercent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
       setProgress(progresspercent);
     },
     (error) => {
       console.log(error);
     },
     () => {
       getDownloadURL(storageRef).then((downloadURL) => {
        console.log(downloadURL);
         setSliderImage4(downloadURL)
       });
     }
   );
            }
  else{
alert('Please select jpg, jpeg and png file only.');
document.getElementById("filesProduct4").value = "";
  }
}

let handleImageChange5 = (e) => {
  e.preventDefault();
  let file = e.target.files[0];              
   let name = moment().unix()+file.name;
     let extFile = name.split('.').pop();
   if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
   const storageRef = storageRefs(storage, `productsImages/${name}`);
   const uploadTask = uploadBytesResumable(storageRef, file);

   uploadTask.on("state_changed",
     (snapshot) => {
       const progresspercent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
       setProgress(progresspercent);
     },
     (error) => {
       console.log(error);
     },
     () => {
       getDownloadURL(storageRef).then((downloadURL) => {
        console.log(downloadURL);
         setSliderImage5(downloadURL)
       });
     }
   );
            }
  else{
alert('Please select jpg, jpeg and png file only.');
document.getElementById("filesProduct5").value = "";
  }
}

    let submit = e => {
        e.preventDefault();
        setSubmitCheck(true);
        if(category == ""){
          alert("Please Select Category.");
          setSubmitCheck(false);
        }
        else if(brandName == ""){
          alert("Please Select Brand Name.");
          setSubmitCheck(false);
        }
        else if(itemName == ""){
          alert("Please Enter Item Name.");
          setSubmitCheck(false);
        }
        else if(priceRetailer == ""){
          alert("Please Enter Retailer Price.");
          setSubmitCheck(false);
        }
        else if(priceWholesaler == ""){
          alert("Please Enter Wholesaler Price.");
          setSubmitCheck(false);
        }
        else if(coverImage == ""){
          alert("Please Upload Cover Image.");
          setSubmitCheck(false);
        }
        else if(inStocks.length < 2){
          alert("Please Enter In Stocks.");
          setSubmitCheck(false);
        }
        else{
        set(ref(database, 'products/' + productId), {
          productId: productId,
          category: category,
          categoryId: categoryId,
brandName: brandName,
coverImage: coverImage,
sliderImage1: sliderImage1,
sliderImage2: sliderImage2,
sliderImage3: sliderImage3,
sliderImage4: sliderImage4,
sliderImage5: sliderImage5,
itemName: itemName,
size: size,
pack: pack,
yarnCount: yarnCount,
composition: composition,
priceRetailer: priceRetailer,
priceWholesaler: priceWholesaler,
pricePer: pricePer,
productDescription: productDescription,
status: status,
promotionalFeatures: promotionalFeatures,
inStocks: inStocks,
createdAt: moment().unix(),
createdBy: 'Admin',
editedBy: '',
editedAt: ''
        }).then(() => {
alert('Product is created successfully.');
setSubmitCheck(false);
window.location.reload();
        });
      }
    }

    let reset = e => {
        e.preventDefault();
        window.location.reload();
    }

    let handlePack = (e) => {
      setPack(e.target.value);
      const selectedIndex = e.target.options.selectedIndex;
      console.log(e.target.options[selectedIndex].getAttribute('data-key'));
      const selectIndexKey = e.target.options[selectedIndex].getAttribute('data-key');
setPricePer(selectIndexKey);
    }

    let handleCategory = e => {
      const selectedIndex = e.target.options.selectedIndex;
      console.log(e.target.options[selectedIndex].getAttribute('data-key'));
      const selectIndexKey = e.target.options[selectedIndex].getAttribute('data-key');
      setCategoryId(selectIndexKey);  
      setCategory(e.target.value); 
     }


    let logOut = e => {
      signOut(auth);
       window.location.href = '/';
       }

    return (
      <section>
      <div className="sidebar">
     
      <div className="logo-details">&nbsp;
      <img src={require("./images/logoF.png")} style={{width: '50px', height: '50px'}} alt=""/> &nbsp;
      <img src={require("./images/elge.png")} style={{width: '150px', height: '80px'}} alt=""/>
      </div>
        <ul className="nav-links">
          <li>
          <Link to={"/dashboard"}>
            <a>
              <i className='bx bx-grid-alt' ></i>
              <span className="links_name">Dashboard</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/category"}>
            <a>
              <i className='bx bx-align-justify' ></i>
              <span className="links_name">Manage Category</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/brand"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Brand</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/pack"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Pack</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageAgents"}>
            <a>
              <i className='bx bx-user-pin' ></i>
              <span className="links_name">Manage Agents</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/viewProduct"}>
            <a>
              <i className='bx bx-box' ></i>
              <span className="links_name">Manage Product</span>
            </a>
            </Link>
          </li>
          <li>
            <a className="active">
            <i className='bx bx-pie-chart-alt-2' ></i>
              <span className="links_name">Create Product</span>
            </a>
          </li>
          <li>
          <Link to={"/manageCustomer"}>
            <a >
              <i className='bx bx-user' ></i>
              <span className="links_name">Manage Customer</span>
            </a>
            </Link>
          </li>
          <li>     
          <Link to={"/createCustomer"}>   
            <a>
            <i className='bx bx-user-plus' ></i>
              <span className="links_name">Create Customer</span>
            </a> 
            </Link>         
          </li>
          <li>
          <Link to={"/orders"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Orders</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/settings"}>
            <a>
              <i className='bx bx-cog' ></i>
              <span className="links_name">Settings</span>
            </a>
            </Link>
          </li>
          <li className="log_out">
          <a onClick={logOut}>
              <i className='bx bx-log-out'></i>
              <span className="links_name">Log out</span>
            </a>
          </li>
        </ul>
    </div>
    <section className="home-section">
      <nav>
        <div className="sidebar-button">
          <i className='bx bx-menu sidebarBtn'></i>
          <span className="dashboard">Create Product</span>
        </div>

        <div className="profile-details">
        <img src={require("./images/male.png")} style={{width: '50px', height: '50px'}} alt=""/>
          <span className="admin_name">Admin</span>
          <i className='bx bx-chevron-down' ></i>
        </div>
      </nav>
  
      <div className="home-content">
<section style={{overflow: 'auto', minHeight: '100%'}}>

<div className="admincreatedocview">

      <div className="container">
          <div className="superadmincreatedoc_details" 
          style={{marginTop:'10px', marginBottom:'20px', backgroundColor:'white', boxShadow:' 0px 0 5px rgba(0, 0, 0, 0.3)', borderRadius:'10px',
         }}>

            <div className="row" style={{display:'flex', justifyContent:'center', padding:'30px 50px'}}>
              <div className="">
                <div className="superadmincreatedoc_info">

                    <div className="row">

                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>CATEGORY </label>
                          <select className="form-control" id="category" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
 name="category" value={category} onChange={handleCategory}>
          <option>Choose Category</option>
          {categoryDatas.map((person, index) => (
<option key={index} data-key={person.categoryId} value={person.categoryName}>{person.categoryName}</option>
))
}
          </select>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>BRAND NAME </label>
                        <select className="form-control" id="brandName" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
 name="brandName" value={brandName} 
      onChange={(event) => { setBrandName( event.target.value ); }}>
          <option>Choose Brand</option>
          {brandDatas.filter(x => x.categoryName == category).map((person, index) => (
<option key={index} data-key={person.brandId} value={person.brandName}>{person.brandName}</option>
))
}
          </select>
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>COVER IMAGE</label>
       
      <input type="file" className="form-control" id="filesProduct" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      name="filesProduct" 
      onChange={handleImageChange} required/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>SLIDER IMAGE 1</label>
       
      <input type="file" className="form-control" id="filesProduct1" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      name="filesProduct1"
      onChange={handleImageChange1}/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
      <label style={{fontWeight:'600', fontSize:'14px'}}>SLIDER IMAGE 2</label>
       
      <input type="file" className="form-control" id="filesProduct2" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      name="filesProduct2"
      onChange={handleImageChange2}/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
      <label style={{fontWeight:'600', fontSize:'14px'}}>SLIDER IMAGE 3</label>
       
      <input type="file" className="form-control" id="filesProduct3" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      name="filesProduct3" 
      onChange={handleImageChange3}/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
      <label style={{fontWeight:'600', fontSize:'14px'}}>SLIDER IMAGE 4</label>
       
      <input type="file" className="form-control" id="filesProduct4" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      name="filesProduct4" 
      onChange={handleImageChange4}/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
      <label style={{fontWeight:'600', fontSize:'14px'}}>SLIDER IMAGE 5</label>
       
      <input type="file" className="form-control" id="filesProduct5" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      name="filesProduct5" 
      onChange={handleImageChange5}/>
      
      </div>

                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>STYLE NAME </label>
       
      <input type="text" className="form-control" id="name" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      placeholder="Style Name" name="itemName" value={itemName} 
      onChange={(event) => { setItemName( event.target.value ); }} required/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>SIZE </label>

                          <input type="text" className="form-control" id="size" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      placeholder="Size" name="size" value={size}
      onChange={(event) => { setSize( event.target.value ); }} required/>     
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>PACK </label>

                          <select className="form-control" id="pack" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
name="pack" value={pack} onChange={handlePack} /*onChange={(event) => { setPack( event.target.value ); }}*/>
          <option>Choose Pack</option>
          {packDatas.filter(x => x.categoryName == category).map((person, index) => (
<option key={index} data-key={person.pricePer} value={person.packName}>{person.packName}</option>
))
}
          </select>   
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>YARN COUNTS </label>
       
      <input type="text" className="form-control" id="yarnCount" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      placeholder="Yarn Count" name="yarnCount" value={yarnCount} 
      onChange={(event) => { setYarnCount( event.target.value ); }} required/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>COMPOSITION </label>
       
      <input type="text" className="form-control" id="composition" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      placeholder="Composition" name="composition" value={composition} 
      onChange={(event) => { setComposition( event.target.value ); }} required/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px', textTransform: 'uppercase'}}>PRICE {pricePer} (Wholesaler)</label>

      <input type="text" className="form-control" id="priceWholesaler" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      placeholder="Price Wholesaler" name="priceWholesaler" value={priceWholesaler} 
      onChange={(event) => { setPriceWholesaler( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px', textTransform: 'uppercase'}}>PRICE {pricePer} (Retailer)</label>

      <input type="text" className="form-control" id="priceRetailer" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      placeholder="Price Retailer" name="priceRetailer" value={priceRetailer} 
      onChange={(event) => { setPriceRetailer( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>STATUS </label>
       
      <select className="form-control" id="status" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
 name="status" value={status} 
      onChange={(event) => { setStatus( event.target.value ); }}>
          <option>Choose Status</option>
<option value="Active">Active</option>
<option value="Inactive">Inactive</option>
          </select>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px', textTransform: 'uppercase'}}>In Stocks</label>

      <input type="text" className="form-control" id="inStocks" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      placeholder="In Stocks" name="inStocks" value={inStocks} 
      onChange={(event) => { setInStocks( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px', textTransform: 'uppercase'}}>Promotional Features</label>

                        <select className="form-control" id="promotionalFeatures" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
 name="promotionalFeatures" value={promotionalFeatures} 
      onChange={(event) => { setPromotionalFeatures( event.target.value ); }}>
<option value="Yes">Yes</option>
<option value="No">No</option>
          </select>

      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>PRODUCT DESCRIPTION</label>

      <textarea className="form-control" rows={10} id="productDescription" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Product Description" name="productDescription" value={productDescription} 
      onChange={(event) => { setProductDescription( event.target.value ); }} required/> 

      </div>
      <hr/>              
    </div>

    <div className="superadmincreatedoc_savebtn" style={{textAlign:'center', marginTop:'10px'}}>
    {submitCheck == false?
                                  <>
                   <button className="btn btn-primary" style={{borderRadius:'5px'}} onClick={submit}>Save</button>&emsp;
                        <button className="btn btn-secondary ml-2" style={{borderRadius:'5px'}} onClick={reset}>Reset</button>
                        </>
                        :
                        <center>
                        <div className="loader"></div>
                        </center>
              }
                   </div>

      </div>
    </div>
    </div>

    </div>
    </div>
          
       
        </div> 
        </section> 
        </div>
        </section> 
</section>
        );

    }
    export default CreateProduct;