import React from 'react';
import {BrowserRouter as Router , Route, Routes, Switch} from 'react-router-dom';
import CreateProduct from './component/products/createProduct';
import EditProduct from './component/products/editProduct';
import ViewProduct from './component/products/viewProduct';
import Dashboard from './component/products/dashboard';
import Orders from './component/products/orders';
import Settings from './component/products/settings';
import ManageCustomer from './component/products/manageCustomer';
import CreateCustomer from './component/products/createCustomer';
import EditCustomer from './component/products/editCustomer';
import CustomerOrder from './component/products/customerOrder';
import Category from './component/products/category';
import Brand from './component/products/brand';
import Pack from './component/products/pack';
import ManageAgents from './component/products/manageAgents';
import CreateAgent from './component/products/createAgent';
import EditAgent from './component/products/editAgent';
import AgentOrder from './component/products/agentOrder';
import Login from './component/login/login';
import ForgotPassword from './component/login/forgotPassword';

function App() {
  return (
    <Router>
    <Routes>
    <Route exact path="/" element={<Login/>} /> 
    <Route exact path="/forgotPassword" element={<ForgotPassword/>} /> 
    <Route exact path="/dashboard" element={<Dashboard/>} /> 
    <Route exact path="/brand" element={<Brand/>} /> 
    <Route exact path="/pack" element={<Pack/>} /> 
    <Route exact path="/category" element={<Category/>} /> 
    <Route exact path="/viewProduct" element={<ViewProduct/>} /> 
    <Route exact path="/createProduct" element={<CreateProduct/>} /> 
    <Route exact path="/editProduct" element={<EditProduct/>} /> 
    <Route exact path="/orders" element={<Orders/>} /> 
    <Route exact path="/settings" element={<Settings/>} /> 
    <Route exact path="/createCustomer" element={<CreateCustomer/>} /> 
    <Route exact path="/manageCustomer" element={<ManageCustomer/>} /> 
    <Route exact path="/editCustomer" element={<EditCustomer/>} /> 
    <Route exact path="/customerOrder" element={<CustomerOrder/>} /> 
    <Route exact path="/manageAgents" element={<ManageAgents/>} /> 
    <Route exact path="/createAgent" element={<CreateAgent/>} /> 
    <Route exact path="/editAgent" element={<EditAgent/>} /> 
    <Route exact path="/agentOrder" element={<AgentOrder/>} /> 
    </Routes>
    </Router>
  );
}

export default App;
