import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage, storageRefs } from '../../config/firebase';
import { getDatabase, ref, push, set, onValue } from "firebase/database";
import { onAuthStateChanged, signOut } from "firebase/auth";
import './viewProduct.css';

function ViewProduct() {

    const [datas, setDatas] = useState([]);
    const [term, setTerm] = useState("");

    const navigate = useNavigate();

    const [adminEmail, setAdminEmail] = useState("");
    const [adminName, setAdminName] = useState("");
  
    useEffect(() => { 
      const getUserData = async () => {
        await onAuthStateChanged(auth, (user) => { 
          if(user){
            onValue(ref(database, '/admin/' + auth.currentUser.uid), (snap) => {
            setAdminName(snap.val().adminName);
            setAdminEmail(snap.val().adminEmail);
        }); 
              }
              else {
                window.location.href = '/'; 
              }
            })
       }
         getUserData(); 
    
    },[]);

      useEffect(() => { 
    const getSideData = async () => {
      let sidebar = document.querySelector(".sidebar");
      let sidebarBtn = document.querySelector(".sidebarBtn");
      sidebarBtn.onclick = function() {
        sidebar.classList.toggle("active");
        if(sidebar.classList.contains("active")){
        sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
      }else
        sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
      }
    }
     getSideData(); 

},[]);   

    useEffect(() => { 
        const getUserData = async () => {

            const starCountRef = ref(database, 'products/');
            onValue(starCountRef, async snapshot => {
                let returnArray = [];
             await snapshot.forEach(element => {
                var item = {};
                item.productId = element.val().productId;
                item.category = element.val().category;
                item.brandName = element.val().brandName;
                item.itemName = element.val().itemName;
                item.size = element.val().size;
                item.pack = element.val().pack;
                item.composition = element.val().composition;
                item.yarnCount = element.val().yarnCount;
                item.priceWholesaler = element.val().priceWholesaler;
                item.priceRetailer = element.val().priceRetailer;
                item.pricePer = element.val().pricePer;
                item.productDescription = element.val().productDescription;
                item.status = element.val().status;
                item.coverImage = element.val().coverImage;
                item.sliderImage1 = element.val().sliderImage1;
                item.sliderImage2 = element.val().sliderImage2;
                item.sliderImage3 = element.val().sliderImage3;
                item.inStocks = element.val().inStocks;
                returnArray.push(item);
            });
            console.table(returnArray);
            setDatas(returnArray);
              

            });         
         }
           getUserData(); 

    },[]); 

        let editProduct = (data, data1) => {
      navigate('/editProduct', {
        state: {
          categoryIdPass: data,
          productIdPass: data1,
        }
      });
    }

    let logOut = e => {
      signOut(auth);
       window.location.href = '/';
       }

    return (
      <section>
      <div className="sidebar">
     
      <div className="logo-details">&nbsp;
      <img src={require("./images/logoF.png")} style={{width: '50px', height: '50px'}} alt=""/> &nbsp;
      <img src={require("./images/elge.png")} style={{width: '150px', height: '80px'}} alt=""/>
      </div>
        <ul className="nav-links">
          <li>
          <Link to={"/dashboard"}>
            <a>
              <i className='bx bx-grid-alt' ></i>
              <span className="links_name">Dashboard</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/category"}>
            <a>
              <i className='bx bx-align-justify' ></i>
              <span className="links_name">Manage Category</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/brand"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Brand</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/pack"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Pack</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageAgents"}>
            <a>
              <i className='bx bx-user-pin' ></i>
              <span className="links_name">Manage Agents</span>
            </a>
            </Link>
          </li>
          <li>
            <a className="active">
              <i className='bx bx-box' ></i>
              <span className="links_name">Manage Product</span>
            </a>
          </li>
          <li>
          <Link to={"/createProduct"}>
            <a>
            <i className='bx bx-pie-chart-alt-2' ></i>
              <span className="links_name">Create Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageCustomer"}>
            <a >
              <i className='bx bx-user' ></i>
              <span className="links_name">Manage Customer</span>
            </a>
            </Link>
          </li>
          <li>     
          <Link to={"/createCustomer"}>   
            <a>
            <i className='bx bx-user-plus' ></i>
              <span className="links_name">Create Customer</span>
            </a> 
            </Link>         
          </li>
          <li>
          <Link to={"/orders"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Orders</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/settings"}>
            <a>
              <i className='bx bx-cog' ></i>
              <span className="links_name">Settings</span>
            </a>
            </Link>
          </li>
          <li className="log_out">
          <a onClick={logOut}>
              <i className='bx bx-log-out'></i>
              <span className="links_name">Log out</span>
            </a>
          </li>
        </ul>
    </div>
    <section className="home-section">
      <nav>
        <div className="sidebar-button">
          <i className='bx bx-menu sidebarBtn'></i>
          <span className="dashboard">Manage Product</span>
        </div>
        <div className="search-box">
          <input type="text" style={{fontSize: '14px'}} placeholder="Search by Category & Item Name & Brand Name..." onChange={(event) => { setTerm( event.target.value ); }} value={term}/>
          <i className='bx bx-search' ></i>
        </div>
        <div className="profile-details">
        <img src={require("./images/male.png")} style={{width: '50px', height: '50px'}} alt=""/>
          <span className="admin_name">Admin</span>
          <i className='bx bx-chevron-down' ></i>
        </div>
      </nav>
  
      <div className="home-content">

      <div className='search_result_list my-2'>
            
            <div className="table-responsive mb-2" style={{borderRadius: '10px',border:'0.5px solid #ccc',borderCollapse: 'collapse'}}>
         <table className='table table-borderless mb-0' style={{border:'none', background: "white"}}>
         <thead className="text-center" style={{backgroundColor:'#e7f4fe', color:'grey'}}>
           <tr>
             <th style={{fontSize: '16px'}}>Image</th>
             <th style={{fontSize: '16px'}}>Brand</th>
             <th style={{fontSize: '16px'}}>Style Name</th>
             <th style={{fontSize: '16px'}}>Category</th>
             <th style={{fontSize: '16px'}}>Wholesaler Price</th>
             <th style={{fontSize: '16px'}}>Retailer Price</th>
             <th style={{fontSize: '16px'}}>Size</th>
             <th style={{fontSize: '16px'}}>Pack</th>
             <th style={{fontSize: '16px'}}>In Stocks</th>
             <th style={{fontSize: '16px'}}>Status</th>
             <th style={{fontSize: '16px'}}>Action</th>
           </tr>
           </thead>
           <tbody>
            {datas.filter(x => x.itemName.toLowerCase().includes(term.toLowerCase()) || x.category.toLowerCase().includes(term.toLowerCase()) || x.brandName.toLowerCase().includes(term.toLowerCase())).map((persons,index) =>
      
           <tr key={index}>
             <td> <img src={persons.coverImage} style={{borderRadius:'5px',width:'60%', height: '7vh', border:'2px solid #ccc', objectFit: 'fill'}}/></td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.brandName}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.itemName}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.category}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>₹{persons.priceWholesaler} {persons.pricePer}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>₹{persons.priceRetailer} {persons.pricePer}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.size}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.pack}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.inStocks <= 0?
             <h6 style={{verticalAlign: 'middle', fontSize: '14px', color: "red"}}>{persons.inStocks}</h6>
             :
             <h6 style={{verticalAlign: 'middle', fontSize: '14px', color: "green"}}>{persons.inStocks}</h6>
            }</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.status}</td>
             <td style={{verticalAlign: 'middle'}}><i className="bx bx-edit" onClick={() => editProduct(persons.category, persons.productId)} style={{fontSize: '24px'}} aria-hidden="true"></i>
                     </td>
           </tr>
          )}
</tbody>
</table>
       </div>
        </div>

          </div>
       

        </section> 
</section>
        );

    }
    export default ViewProduct;