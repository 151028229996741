import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage, storageRefs } from '../../config/firebase';
import { getDatabase, ref, push, set, onValue } from "firebase/database";
import { onAuthStateChanged, signOut } from "firebase/auth";
import './viewProduct.css';

function ManageAgents() {

    const [datas, setDatas] = useState([]);
    const [term, setTerm] = useState("");

    const [agentId, setAgentId] = useState("");
    const [agentName, setAgentName] = useState("");
      const [addressLine, setAddressLine] = useState("");
      const [addressLine1, setAddressLine1] = useState("");
      const [city, setCity] = useState("");
      const [pinCode, setPinCode] = useState("");
      const [state, setState] = useState("");
      const [stateCode, setStateCode] = useState("");
      const [phoneNumber, setPhoneNumber] = useState("");
      const [agentImage, setAgentImage] = useState(""); 

      const navigate = useNavigate();

      const [adminEmail, setAdminEmail] = useState("");
      const [adminName, setAdminName] = useState("");
    
      useEffect(() => { 
        const getUserData = async () => {
          await onAuthStateChanged(auth, (user) => { 
            if(user){
              onValue(ref(database, '/admin/' + auth.currentUser.uid), (snap) => {
              setAdminName(snap.val().adminName);
              setAdminEmail(snap.val().adminEmail);
          }); 
                }
                else {
                  window.location.href = '/'; 
                }
              })
         }
           getUserData(); 
      
      },[]);

      useEffect(() => { 
        const getSideData = async () => {
          let sidebar = document.querySelector(".sidebar");
          let sidebarBtn = document.querySelector(".sidebarBtn");
          sidebarBtn.onclick = function() {
            sidebar.classList.toggle("active");
            if(sidebar.classList.contains("active")){
            sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
          }else
            sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
          }
        }
         getSideData(); 
    
    },[]); 

    useEffect(() => { 
        const getUserData = async () => {

            const starCountRef = ref(database, 'agent/');
            onValue(starCountRef, async snapshot => {
                let returnArray = [];
             await snapshot.forEach(element => {
                var item = {};
                item.agentName = element.val().agentName;
                item.agentImage = element.val().agentImage;
                item.agentId = element.val().agentId;
                item.addressLine = element.val().addressLine;
                item.addressLine1 = element.val().addressLine1;
                item.city = element.val().city;
                item.pinCode = element.val().pinCode;
                item.state = element.val().state;
                item.status = element.val().status;
                item.stateCode = element.val().stateCode;
                item.phoneNumber = element.val().phoneNumber;
                returnArray.push(item);
              });
              console.log(returnArray);
              setDatas(returnArray);
            });         
         }
           getUserData(); 

    },[]); 

    let agentEdit = (data) => {
      navigate('/editAgent', {
        state: {
          agentIdPass: data,
        }
      });
    }

    let customerOrder = (data, data1) => {
      navigate('/agentOrder', {
        state: {
          agentIdPass: data,
          agentNamePass: data1
        }
      });
    }

    let logOut = e => {
      signOut(auth);
       window.location.href = '/';
       }

    return (
      <section>
      <div className="sidebar">
     
      <div className="logo-details">&nbsp;
      <img src={require("./images/logoF.png")} style={{width: '50px', height: '50px'}} alt=""/> &nbsp;
      <img src={require("./images/elge.png")} style={{width: '150px', height: '80px'}} alt=""/>
      </div>
        <ul className="nav-links">
          <li>
          <Link to={"/dashboard"}>
            <a>
              <i className='bx bx-grid-alt' ></i>
              <span className="links_name">Dashboard</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/category"}>
            <a>
              <i className='bx bx-align-justify' ></i>
              <span className="links_name">Manage Category</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/brand"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Brand</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/pack"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Pack</span>
            </a>
            </Link>
          </li>
          <li>
            <a className="active">
              <i className='bx bx-user-pin' ></i>
              <span className="links_name">Manage Agents</span>
            </a>
          </li>
          <li>
          <Link to={"/viewProduct"}>
            <a>
              <i className='bx bx-box' ></i>
              <span className="links_name">Manage Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/createProduct"}>
            <a>
            <i className='bx bx-pie-chart-alt-2' ></i>
              <span className="links_name">Create Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageCustomer"}>
            <a>
              <i className='bx bx-user' ></i>
              <span className="links_name">Manage Customer</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/createCustomer"}>
            <a>
            <i className='bx bx-user-plus' ></i>
              <span className="links_name">Create Customer</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/orders"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Orders</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/settings"}>
            <a>
              <i className='bx bx-cog' ></i>
              <span className="links_name">Settings</span>
            </a>
            </Link>
          </li>
          <li className="log_out">
          <a onClick={logOut}>
              <i className='bx bx-log-out'></i>
              <span className="links_name">Log out</span>
            </a>
          </li>
        </ul>
    </div>
    <section className="home-section">
      <nav>
        <div className="sidebar-button">
          <i className='bx bx-menu sidebarBtn'></i>
          <span className="dashboard">Manage Agents</span>
        </div>
        <div className="search-box">
          <input type="text" placeholder="Search by Agent Name" onChange={(event) => { setTerm( event.target.value ); }} value={term}/>
          <i className='bx bx-search' ></i>
        </div>
        <div className="profile-details">
        <img src={require("./images/male.png")} style={{width: '50px', height: '50px'}} alt=""/>
          <span className="admin_name">Admin</span>
          <i className='bx bx-chevron-down' ></i>
        </div>
      </nav>

      <div className="home-content">
      <div className="" style={{marginTop:'5px', marginRight: '10px', marginLeft: '20px'}}>
                        <button className="btn btn-primary" style={{borderRadius:'5px'}} onClick={() => navigate("/createAgent")}>Create Agent</button>                     
                   </div>
      <div className='search_result_list my-2s'>
            <div className="row">
            {datas.filter(x => x.agentName.toLowerCase().includes(term.toLowerCase())).map((persons,index) =>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 my-2" key={index}>
          <div className="search_result_box" style={{backgroundColor:'white', padding:'15px', boxShadow:'1px 1px 3px rgba(0, 93, 214, 0.9)'}}>
            <div className="row">
              <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 search_result_doctoricon"> 
              <center>
                        {persons.agentImage == ""?
               <div style={{borderRadius:'7px',width:'100%', height: '16vh', border:'1px solid #ccc', marginTop: '5px', marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
               <h6 style={{flex: 1, alignSelf: 'center'}}>No Image</h6>
                </div>  
       :

       <img src={persons.agentImage} style={{borderRadius:'7px',width:'100%', height: '16vh', border:'2px solid #ccc', objectFit: 'fill'}}/>
}
           </center>
             
                   </div>
              <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 p-0">
                <div className="search_result_doctorName">
                  <center>
                <span style={{fontSize:'16px', fontWeight: 300, cursor: 'pointer'}}><b>{persons.agentName}</b>&emsp;
                <i className="bx bx-edit" onClick={() => agentEdit(persons.agentId)} style={{fontSize: '20px', color:'#1e40e9'}} aria-hidden="true"></i></span>
               
                </center>
                 </div>

                <div className="hospital_details">
                  <span style={{fontSize:'14px', color:'#666', cursor: 'pointer'}}>Ph.No: <span style={{fontSize:'14px', color:'#1e40e9', fontWeight: 400}}>{persons.phoneNumber.substring(3)}</span></span><br/>
                      <span style={{fontSize:'14px', color:'#666', cursor: 'pointer'}}>Address: <span style={{fontSize:'14px', color:'#1e40e9', fontWeight: 400}}>{persons.addressLine}, {persons.addressLine1}, {persons.city}, {persons.state}, {persons.pinCode}</span></span><br/>
{persons.status == "Active"?
<>
<span style={{fontSize:'14px', color:'#666', cursor: 'pointer'}}>Status: <span style={{fontSize:'14px', color:'green', fontWeight: 400}}>{persons.status}</span></span><br/>
</>
:
<>
<span style={{fontSize:'14px', color:'#666', cursor: 'pointer'}}>Status: <span style={{fontSize:'14px', color:'red', fontWeight: 400}}>{persons.status}</span></span><br/>
</>
}
                                  </div>

                                  <button type="button" data-dismiss="modal" className="btn btn-primary" onClick={() => customerOrder(persons.agentId, persons.agentName)}>Reports</button>

              </div>

                </div>

            </div>
          </div>
          )}
        </div>

          </div>
          </div>

        </section> 
</section>
        );

    }
    export default ManageAgents;