import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage, storageRefs } from '../../config/firebase';
import { getDatabase, ref, push, set, orderByKey, limitToLast, onValue } from "firebase/database";
import { getDownloadURL, uploadBytesResumable, uploadBytes, listAll, list,} from "firebase/storage";
import { onAuthStateChanged, signOut } from "firebase/auth";

function CreateCustomer() {

  const [customerType, setCustomerType] = useState("Wholesaler");
  const [agentName, setAgentName] = useState("");
  const [agentId, setAgentId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [shopName, setShopName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [addressLine, setAddressLine] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [locality, setLocality] = useState("");
    const [city, setCity] = useState("");
    const [district, setDistrict] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [commAddressLine, setCommAddressLine] = useState("");
    const [commAddressLine1, setCommAddressLine1] = useState("");
    const [commCity, setCommCity] = useState("");
    const [commDistrict, setCommDistrict] = useState("");
    const [commPinCode, setCommPinCode] = useState("");
    const [gstNumber, setGstNumber] = useState("");
    const [state, setState] = useState("");
    const [stateCode, setStateCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");
    const [landlineNumber, setLandlineNumber] = useState("");
    const [progress, setProgress] = useState(0);
    const [shopImage, setShopImage] = useState("");   
    const [profileImage, setProfileImage] = useState(""); 

    const [courier1, setCourier1] = useState(""); 
    const [courier2, setCourier2] = useState(""); 
    const [courier3, setCourier3] = useState(""); 

    const [lorry1, setLorry1] = useState(""); 
    const [lorry2, setLorry2] = useState("");
    const [lorry3, setLorry3] = useState("");

    const [deliveryAddress, setDeliveryAddress] = useState(false);

    const [status, setStatus] = useState("Active"); 

    const [agentDatas, setAgentDatas] = useState([]);

    const [phoneDatas, setPhoneDatas] = useState([]);

    const [adminEmail, setAdminEmail] = useState("");
    const [adminName, setAdminName] = useState("");
    const [submitCheck, setSubmitCheck] = useState(false);
  
    useEffect(() => { 
      const getUserData = async () => {
        await onAuthStateChanged(auth, (user) => { 
          if(user){
            onValue(ref(database, '/admin/' + auth.currentUser.uid), (snap) => {
            setAdminName(snap.val().adminName);
            setAdminEmail(snap.val().adminEmail);
        }); 
              }
              else {
                window.location.href = '/'; 
              }
            })
       }
         getUserData(); 
    
    },[]);

    useEffect(() => { 
      const getSideData = async () => {
        let sidebar = document.querySelector(".sidebar");
        let sidebarBtn = document.querySelector(".sidebarBtn");
        sidebarBtn.onclick = function() {
          sidebar.classList.toggle("active");
          if(sidebar.classList.contains("active")){
          sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
        }else
          sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
        }
      }
       getSideData(); 
  
  },[]); 

  useEffect(() => { 
    const getUserData2 = async () => {

      const starCountRef = ref(database, 'users/');
      onValue(starCountRef, async snapshot => {
        if (snapshot.exists()) {
        let returnArray2 = [];
        snapshot.forEach((childSnapshot) => {
          returnArray2.push(childSnapshot.key);
        });
       console.log(returnArray2);
       setPhoneDatas(returnArray2);
  }
  else {
    setPhoneDatas([]);
  }
      });         
   }
     getUserData2(); 

},[]); 

  useEffect(() => { 
    const getUserData1 = async () => {

      const starCountRef = ref(database, 'agent/');
      onValue(starCountRef, async snapshot => {
        let returnArray1 = [];
        if (snapshot.exists()) {
        snapshot.forEach((element) => {
          var item = {};
          item.agentId = element.val().agentId;
          item.agentName = element.val().agentName;
          returnArray1.push(item);
        });
        console.log(returnArray1);
setAgentDatas(returnArray1);
  }
  else {
  }
      });         
   }
     getUserData1(); 

},[]); 

    useEffect(() => { 
      const getUserData = async () => {

        const starCountRef = ref(database, 'customer/');
        onValue(starCountRef, async snapshot => {
          if (snapshot.exists()) {
          let returnArray = [];
          snapshot.forEach((childSnapshot) => {
            returnArray.push(childSnapshot.key);
          });
          let output = returnArray.pop();
let output1 = output.slice(-4);
let output2 = parseInt(output1) + parseInt(1);
let output3 = "";
if(output2.toString().length == 1){
output3 = 'ELETN' + '000' + output2;
}
else if(output2.toString().length == 2){
  output3 = 'ELETN' + '00' + output2;
  }
  else if(output2.toString().length == 3){
    output3 = 'ELETN' + '0' + output2;
    }
    else{
      output3 = 'ELETN' + output2;
      }
      console.log(snapshot.val());
setCustomerId(output3);
    }
    else {
      setCustomerId('ELETN0001');
      console.log("No data available");
    }
        });         
     }
       getUserData(); 

},[]); 

    let handleImageChange = (e) => {
      e.preventDefault();
      let file = e.target.files[0];              
       let name = moment().unix()+file.name;
       let extFile = name.split('.').pop();
       if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
      const storageRef = storageRefs(storage, `profile/${name}`);
       const uploadTask = uploadBytesResumable(storageRef, file);
   
       uploadTask.on("state_changed",
         (snapshot) => {
           const progresspercent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
           setProgress(progresspercent);
         },
         (error) => {
           console.log(error);
         },
         () => {
           getDownloadURL(storageRef).then((downloadURL) => {
            console.log(downloadURL);
            setProfileImage(downloadURL);
           });
         }
       );
        }
       else{
alert('Please select jpg, jpeg and png file only.');
document.getElementById("filesProfile").value = "";
       }
}

let handleImageChange1 = (e) => {
  e.preventDefault();
  let file = e.target.files[0];              
   let name = moment().unix()+file.name;
   let extFile = name.split('.').pop();
   if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
   const storageRef = storageRefs(storage, `shops/${name}`);
   const uploadTask = uploadBytesResumable(storageRef, file);

   uploadTask.on("state_changed",
     (snapshot) => {
       const progresspercent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
       setProgress(progresspercent);
     },
     (error) => {
       console.log(error);
     },
     () => {
       getDownloadURL(storageRef).then((downloadURL) => {
        console.log(downloadURL);
        setShopImage(downloadURL);
       });
     }
   );
  }
  else{
alert('Please select jpg, jpeg and png file only.');
document.getElementById("filesShop").value = "";
  }
}

    let submit = e => {
        e.preventDefault();
        setSubmitCheck(true);
        if(shopName == ""){
          alert("Please enter shop name.");
          setSubmitCheck(false);
        }
      else if(shopImage == ""){
          alert("Please add shop image.");
          setSubmitCheck(false);
        }
        else if(customerName == ""){
          alert("Please enter customer name.");
          setSubmitCheck(false);
        }
        else if(gstNumber == ""){
          alert("Please enter GST Number.");
          setSubmitCheck(false);
        }
else if(phoneNumber == ""){
  alert("Please enter phone number.");
  setSubmitCheck(false);
}
else if(phoneNumber.length < 10){
  alert("Please enter 10 digit phone number.");
  setSubmitCheck(false);
}
else if(addressLine == ""){
  alert("Please enter address.");
  setSubmitCheck(false);
}
else if(locality == ""){
  alert("Please enter locality.");
  setSubmitCheck(false);
}
else if(city == ""){
  alert("Please enter city.");
  setSubmitCheck(false);
}
else if(pinCode == ""){
  alert("Please enter pincode.");
  setSubmitCheck(false);
}
else if(state == ""){
  alert("Please enter state.");
  setSubmitCheck(false);
}
else if(stateCode == ""){
  alert("Please enter state code.");
  setSubmitCheck(false);
}
else{
  if(phoneDatas.indexOf(`+91${phoneNumber}`)!==-1){
    alert("Phone number is already in use.");
    setSubmitCheck(false);
    } 
  else  {  
    if (window.confirm(`Please confirm phone number ${phoneNumber}. It is not editable?`)){
      set(ref(database, 'customer/' + customerId), {
        customerId: customerId,
        shopName: shopName,
        shopImage: shopImage,
customerName: customerName,
profileImage: profileImage,
gstNumber: gstNumber,
phoneNumber: "+91" + phoneNumber,
secondaryPhoneNumber: secondaryPhoneNumber,
landlineNumber: landlineNumber,
addressLine: addressLine,
addressLine1: addressLine1,
locality: locality,
city: city,
district: district,
pinCode: pinCode,
commAddressLine: commAddressLine,
commAddressLine1: commAddressLine1,
commCity: commCity,
commDistrict: commDistrict,
commPinCode: commPinCode,
state: state,
stateCode: stateCode,
customerType: customerType,
agentName: agentName,
agentId: agentId,
createdBy: 'Admin',
editedBy: "",
editedAt: "",
createdAt: moment().unix(),
courier1: courier1,
courier2: courier2,
courier3: courier3,
lorry1: lorry1,
lorry2: lorry2,
lorry3: lorry3,
status: status
      }).then(() => {

        set(ref(database, 'users/' + "+91" + phoneNumber + "/"), {
          id: customerId,
          phoneNumber: "+91" + phoneNumber,
          role: "Customer",
          status: status
        }).then(() => {    
alert('Customer is created successfully.');
setSubmitCheck(false);
window.location.reload();
});
      });
    }
    else{
      setSubmitCheck(false);
      return false;
    }     
  }  
      }
    }

    let reset = e => {
        e.preventDefault();
        window.location.reload();
    }

    let handleRole = e => {
      setCustomerType(e.target.value); 
     }

     let handleAgent = e => {
      const selectedIndex = e.target.options.selectedIndex;
      console.log(e.target.options[selectedIndex].getAttribute('data-key'));
      const selectIndexKey = e.target.options[selectedIndex].getAttribute('data-key');
      setAgentId(selectIndexKey);  
      setAgentName(e.target.value); 
     }

     const handleDelivery = () => {
      setDeliveryAddress(!deliveryAddress); 
if(deliveryAddress == false){
  setCommAddressLine(addressLine);
  setCommAddressLine1(addressLine1);
  setCommCity(city);
  setCommDistrict(district);
  setCommPinCode(pinCode);
}
else{  
  setCommAddressLine("");
  setCommAddressLine1("");
  setCommCity("");
  setCommDistrict("");
  setCommPinCode("");
}
     }

     let logOut = e => {
      signOut(auth);
       window.location.href = '/';
       }

    return (
      <section>
      <div className="sidebar">
     
      <div className="logo-details">&nbsp;
      <img src={require("./images/logoF.png")} style={{width: '50px', height: '50px'}} alt=""/> &nbsp;
      <img src={require("./images/elge.png")} style={{width: '150px', height: '80px'}} alt=""/>
      </div>
        <ul className="nav-links">
          <li>
          <Link to={"/dashboard"}>
            <a>
              <i className='bx bx-grid-alt' ></i>
              <span className="links_name">Dashboard</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/category"}>
            <a>
              <i className='bx bx-align-justify' ></i>
              <span className="links_name">Manage Category</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/brand"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Brand</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/pack"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Pack</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageAgents"}>
            <a>
              <i className='bx bx-user-pin' ></i>
              <span className="links_name">Manage Agents</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/viewProduct"}>
            <a>
              <i className='bx bx-box' ></i>
              <span className="links_name">Manage Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/createProduct"}>
            <a>
            <i className='bx bx-pie-chart-alt-2' ></i>
              <span className="links_name">Create Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageCustomer"}>
            <a >
              <i className='bx bx-user' ></i>
              <span className="links_name">Manage Customer</span>
            </a>
            </Link>
          </li>
          <li>        
            <a className="active">
            <i className='bx bx-user-plus' ></i>
              <span className="links_name">Create Customer</span>
            </a>          
          </li>
          <li>
          <Link to={"/orders"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Orders</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/settings"}>
            <a>
              <i className='bx bx-cog' ></i>
              <span className="links_name">Settings</span>
            </a>
            </Link>
          </li>
          <li className="log_out">
          <a onClick={logOut}>
              <i className='bx bx-log-out'></i>
              <span className="links_name">Log out</span>
            </a>
          </li>
        </ul>
    </div>
    <section className="home-section">
      <nav>
        <div className="sidebar-button">
          <i className='bx bx-menu sidebarBtn'></i>
          <span className="dashboard">Create Customer</span>
        </div>

        <div className="profile-details">
        <img src={require("./images/male.png")} style={{width: '50px', height: '50px'}} alt=""/>
          <span className="admin_name">Admin</span>
          <i className='bx bx-chevron-down' ></i>
        </div>
      </nav>
  
      <div className="home-content">
<section style={{overflow: 'auto', minHeight: '100%'}}>

<div className="admincreatedocview">

      <div className="container">
          <div className="superadmincreatedoc_details" 
          style={{marginTop:'10px', marginBottom:'20px', backgroundColor:'white', boxShadow:' 0px 0 5px rgba(0, 0, 0, 0.3)', borderRadius:'10px',
         }}>

            <div className="row" style={{display:'flex', justifyContent:'center', padding:'30px 50px'}}>
              <div className="">
                <div className="superadmincreatedoc_info">

                <div className="row">

                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                <label style={{fontWeight:'600', fontSize:'14px', whiteSpace: 'nowrap'}}>CUSTOMER TYPE<span style={{color:'red'}}>*</span>&emsp;</label>
      <div className="rows">
                     
                        <label className='ml-1 input-field' style={{fontSize: '14px', whiteSpace: 'nowrap'}}>
      <input type="radio" checked={customerType === 'Wholesaler'} name="Wholesaler" value="Wholesaler" onChange={handleRole}/>
     &nbsp; Wholesaler
    </label>&emsp;
    <label className='ml-1 input-field' style={{fontSize: '14px', whiteSpace: 'nowrap'}}>
    <input type="radio" checked={customerType === 'Retailer'} name="Retailer" value="Retailer" onChange={handleRole}/>
     &nbsp; Retailer
    </label>&emsp;
              
</div>

</div>

<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>PHONE NUMBER<span style={{color:'red'}}>*</span>&emsp; </label>
                        <div class="input-group mb-3">
  <div class="input-group-prepend">
    <span class="input-group-text" id="basic-addon1">+91</span>
  </div>
      <input type="text" className="form-control" id="phoneNumber" style={{textIndent:'10px', fontSize: '12px'}} 
      placeholder="Phone Number" name="phoneNumber" value={phoneNumber} 
      onChange={(event) => { setPhoneNumber( event.target.value ); }} required/>
      </div>
      </div>

      </div>


                    <div className="row">

                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>AGENT NAME </label>
                        <select className="form-control" id="agentName" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
 name="agentName" value={agentName} onChange={handleAgent}>
          <option>Choose Agent</option>
          {agentDatas.map((person, index) => (
<option key={index} data-key={person.agentId} value={person.agentName}>{person.agentName}</option>
))
}
          </select>
      </div>

                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>SHOP NAME<span style={{color:'red'}}>*</span> </label>
       
      <input type="text" className="form-control" id="shopName" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Shop Name" name="shopName" value={shopName} 
      onChange={(event) => { setShopName( event.target.value ); }} required/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>LOGO/SHOP IMAGE<span style={{color:'red'}}>*</span>&emsp;</label>
       
      <input type="file" className="form-control" id="filesShop" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      name="filesShop" accept="image/*" onChange={handleImageChange1}/>
      
      </div>

                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>CUSTOMER NAME<span style={{color:'red'}}>*</span>&emsp; </label>
       
      <input type="text" className="form-control" id="customerName" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Customer Name" name="customerName" value={customerName} 
      onChange={(event) => { setCustomerName( event.target.value ); }} required/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>PROFILE IMAGE</label>
       
      <input type="file" className="form-control" id="filesProfile" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      name="filesProfile" accept="image/*" onChange={handleImageChange} required/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>GST NUMBER<span style={{color:'red'}}>*</span>&emsp; </label>
       
      <input type="text" className="form-control" id="gstNumber" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="GST Number" name="gstNumber" value={gstNumber} 
      onChange={(event) => { setGstNumber( event.target.value ); }} required/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>SECONDARY PHONE NUMBER&emsp; </label>
       
      <input type="text" className="form-control" id="secondaryPhoneNumber" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Phone Number" name="secondaryPhoneNumber" value={secondaryPhoneNumber} 
      onChange={(event) => { setSecondaryPhoneNumber( event.target.value ); }} required/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>LANDLINE NUMBER&emsp; </label>
       
      <input type="text" className="form-control" id="landlineNumber" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Landline Number" name="landlineNumber" value={landlineNumber} 
      onChange={(event) => { setLandlineNumber( event.target.value ); }} required/>
      
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>BILLING ADDRESS<span style={{color:'red'}}>*</span>&emsp;</label>

      <input type="text" className="form-control" id="addressLine" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', marginBottom: '10px'}} 
      placeholder="Address Line 1" name="addressLine" value={addressLine} 
      onChange={(event) => { setAddressLine( event.target.value ); }} required/> 

<input type="text" className="form-control" id="addressLine1" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Address Line 2" name="addressLine1" value={addressLine1} 
      onChange={(event) => { setAddressLine1( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>LOCALITY<span style={{color:'red'}}>*</span>&emsp; </label>

      <input type="text" className="form-control" id="locality" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Locality" name="locality" value={locality} 
      onChange={(event) => { setLocality( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>CITY<span style={{color:'red'}}>*</span>&emsp; </label>

      <input type="text" className="form-control" id="city" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="City" name="city" value={city} 
      onChange={(event) => { setCity( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>DISTRICT<span style={{color:'red'}}>*</span>&emsp; </label>

      <input type="text" className="form-control" id="district" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="District" name="district" value={district} 
      onChange={(event) => { setDistrict( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>PINCODE<span style={{color:'red'}}>*</span>&emsp; </label>

      <input type="text" className="form-control" id="pinCode" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Pin Code" name="pinCode" value={pinCode} 
      onChange={(event) => { setPinCode( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>SHIPPING ADDRESS  </label>
                        <br></br>
                        <label className='ml-1 input-field' style={{fontSize: '14px'}}>
<input type="checkbox" checked={deliveryAddress} onChange={handleDelivery}/>
&nbsp; Same as billing address
</label>
      <input type="text" className="form-control" id="commAddressLine" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', marginBottom: '10px'}} 
      placeholder="Address Line 1" name="commAddressLine" value={commAddressLine} 
      onChange={(event) => { setCommAddressLine( event.target.value ); }} required/> 

<input type="text" className="form-control" id="commAddressLine1" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Address Line 2" name="commAddressLine1" value={commAddressLine1} 
      onChange={(event) => { setCommAddressLine1( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>CITY </label>

      <input type="text" className="form-control" id="commCity" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="City" name="commCity" value={commCity} 
      onChange={(event) => { setCommCity( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>DISTRICT </label>

      <input type="text" className="form-control" id="district" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="District" name="district" value={commDistrict} 
      onChange={(event) => { setCommDistrict( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>PINCODE </label>

      <input type="text" className="form-control" id="commPinCode" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Pin Code" name="commPinCode" value={commPinCode} 
      onChange={(event) => { setCommPinCode( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>STATE<span style={{color:'red'}}>*</span>&emsp; </label>

      <input type="text" className="form-control" id="state" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="State" name="state" value={state} 
      onChange={(event) => { setState( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>STATE CODE<span style={{color:'red'}}>*</span>&emsp; </label>

      <input type="text" className="form-control" id="stateCode" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="State Code" name="stateCode" value={stateCode} 
      onChange={(event) => { setStateCode( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>COURIER 1&emsp; </label>

      <input type="text" className="form-control" id="courier1" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Courier 1" name="courier1" value={courier1} 
      onChange={(event) => { setCourier1( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>COURIER 2&emsp; </label>

      <input type="text" className="form-control" id="courier2" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Courier 2" name="courier2" value={courier2} 
      onChange={(event) => { setCourier2( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>COURIER 3&emsp; </label>

      <input type="text" className="form-control" id="courier3" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Courier 3" name="courier3" value={courier3} 
      onChange={(event) => { setCourier3( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>LORRY 1&emsp; </label>

      <input type="text" className="form-control" id="lorry1" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Lorry 1" name="lorry1" value={lorry1} 
      onChange={(event) => { setLorry1( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>LORRY 2&emsp; </label>

      <input type="text" className="form-control" id="lorry2" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Lorry 2" name="lorry2" value={lorry2} 
      onChange={(event) => { setLorry2( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>LORRY 3&emsp; </label>

      <input type="text" className="form-control" id="lorry3" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Lorry 3" name="lorry3" value={lorry3} 
      onChange={(event) => { setLorry3( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>STATUS </label>
       
      <select className="form-control" id="status" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
 name="status" value={status} 
      onChange={(event) => { setStatus( event.target.value ); }}>
<option value="Active">Active</option>
<option value="Inactive">Inactive</option>
          </select>
      
      </div>

      <hr/>              
    </div>

    <div className="superadmincreatedoc_savebtn" style={{textAlign:'center', marginTop:'10px'}}>
    {submitCheck == false?
                                  <>
                   <button className="btn btn-primary" style={{borderRadius:'5px'}} onClick={submit}>Save</button>&emsp;
                        <button className="btn btn-secondary ml-2" style={{borderRadius:'5px'}} onClick={reset}>Reset</button>
                     </>
                        :
                        <center>
                        <div className="loader"></div>
                        </center>
              }     
                   </div>

      </div>
    </div>
    </div>

    </div>
    </div>
          
       
        </div> 
        </section> 
        </div>
        </section> 
</section>
        );

    }
    export default CreateCustomer;