import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage } from '../../config/firebase';
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import './login.css';

const d = new Date();
let year = d.getFullYear();

function Login() {

  const [yearFetch, setYearFetch] = useState(year);

  const [email, setEmail] = useState("");
  const [validates, setvalidates] = useState(false);
  const [adminEmail, setAdminEmail] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [errorMessageAuth, setErrorMessageAuth] = useState('');
  const [emailValidation, setEmailValidation] = useState(false);
  const [emailMessage, setEmailMessage] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState(false);

  const [userAgent, setUserAgent] = useState(navigator.userAgent);

const navigate = useNavigate();


useEffect(() => { 
  const getUserData = async () => {
    await onAuthStateChanged(auth, (user) => { 
      if(user){
        console.log(user.uid);
        window.location.href = '/dashboard'; 
          }
          else {
            
          }
        })
   }
     getUserData(); 

},[]);

let validate = (text) => {
  console.log(text);
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ ;
  if(reg.test(text) === false)
  {
  setEmail(text);
  setvalidates(false);
  return false;
    }
  else {
    setEmail(text);
    setvalidates(true);
  }
  }

  let showPasswords = e => {
    setShowPassword(!showPassword);
  }

  let handleEmail = e => {
    validate(e.target.value);
    setEmailMessage(false);
    setEmailValidation(false);
    setErrorMessageAuth('');
  }

  let handlePassword = e => {
    setPassword( e.target.value );
    setPasswordMessage(false);
    setErrorMessageAuth('');
  }

let submit = e => {
  if(email == ''){
    setEmailMessage(true);
  }
  else if(validates === false){
    setEmailValidation(true);
}
else if(password.length == ''){
  setPasswordMessage(true);
}
    else {
   signInWithEmailAndPassword(auth, email, password).then(function(user) {
       console.log(auth.currentUser.uid);
       }).catch(function(error) {
        setErrorMessageAuth(error.message);
       }); 
    }
}

let handleKeypress = e => {
if (e.key == 'Enter') {
  submit();
}
};
 

return (
  <section className="signin">

    <a className="navbar-brand" style={{marginTop: '15px', marginLeft: '15px'}}>
    <img src={require("./images/logoF.png")} style={{width: '70px', height: '70px'}} alt=""/> &nbsp;
      <img src={require("./images/elge.png")} style={{width: '150px', height: '80px'}} alt=""/>
            </a>

  <div className="Doctor_login_page">
<div className="Doctor_login_page_logindetails">
<div className="container">
  <div className="row m-0">
  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center'}}>

      <div className="Doctor_login_pageform">
        <div className="title mb-4">
          <center>
          <h3 className="my-2">Sign in</h3>
          </center>

        </div>
        <div className="form">
          <div className="email my-1 d-flex rows">
                <i className="fa fa-envelope-o" aria-hidden="true"></i> 
                <input className="input-field" type="email" value={email} onChange={handleEmail} onKeyPress={handleKeypress} autoCapitalize="none" placeholder="Email Address" /*onChange={(event) => validate(event.target.value)}*/ required/>
            </div>
            <br></br>
            <div className="password my-1 d-flex rows">
                <i className="fa fa-lock" aria-hidden="true"></i> 
                <input className="input-field" value={password} type={showPassword ? "password" : "text"} autoCapitalize="none" /*onChange={(event) => { setPassword( event.target.value ); }}*/ onChange={handlePassword} onKeyPress={handleKeypress} placeholder="Password" required/>
                {showPassword === true?
                <i className="fa fa-eye" aria-hidden="true" onClick={showPasswords}></i>
      :
      <i className="fa fa-eye-slash" aria-hidden="true" onClick={showPasswords}></i>
      
               }
                
            </div>
            <br></br>
            {errorMessage == true?
        <div className='alert alert-danger alert-dismissible'>
        <button type='button' className='close' onClick={() => setErrorMessage(false)}>&times;</button>
<strong>Warning!</strong> Email or Password is entered wrongly.
</div>
             :
             null
             }

{alertMessage == true?
<div className='alert alert-danger alert-dismissible'>
<button type='button' className='close' onClick={() => setAlertMessage(false)}>&times;</button>
<strong>Warning!</strong> Current Email is not a superadmin.
</div>
            
             :
             null
             }

       {passwordMessage === true?
  
  <div className='alert alert-danger alert-dismissible'>
          <button type='button' className='close' onClick={() => setPasswordMessage(false)}>&times;</button>
<strong>Warning!</strong> Please enter password.
</div>
:
null
}

{emailValidation == true?
 
 <div className='alert alert-danger alert-dismissible'>
         <button type='button' className='close' onClick={() => setEmailValidation(false)}>&times;</button>
<strong>Warning!</strong> Email is badly formatted.
</div>
:
null
}

{emailMessage == true?
     
 <div className='alert alert-danger alert-dismissible'>
         <button className='close' type='button' onClick={() => setEmailMessage(false)}>&times;</button>
<strong>Warning!</strong> Email is empty.
</div>

:
null
}

{errorMessageAuth == ''?
null    
:

<div className='alert alert-danger alert-dismissible'>
<button type='button' className='close' onClick={() => setErrorMessageAuth('')}>&times;</button>
{errorMessageAuth}
</div>
}

<div className="" style={{paddingBottom: '10px', float: 'right'}}>
                <a onClick={() => navigate("/forgotPassword")} className="font" style={{color:'gray', cursor: 'pointer'}}>Forgot password?</a>
             </div>

          <div className="button">
            <center>
            <button onClick={submit} style={{width: "200px"}}>Sign in <i className="fa fa-long-arrow-right text-white" aria-hidden="true"></i></button>
            </center>
          </div>
        </div>
      </div>
    
  </div>
  </div>
</div>
</div>

<div className="footer-section copyrights-text">

            <p className="" style={{color: '#000'}}>&emsp;© Copyright 2021 - {yearFetch}, Elge Enterprises | All Rights Reserved    <small className="" style={{color: '#000', float: 'right', marginRight: '35px'}}>Telth Systems Private limited.</small></p>
        
        </div>
</div>      
  </section>
    );
}
export default Login;
