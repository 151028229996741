import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import { auth, database, storage } from '../../config/firebase';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { getDatabase, ref, push, set, child, onValue, update } from "firebase/database";
import moment from 'moment';

function Dashboard() {
  const [adminEmail, setAdminEmail] = useState("");
  const [adminName, setAdminName] = useState("");

  const [productDatas, setProductDatas] = useState([]);
  const [orderDatas, setOrderDatas] = useState([]);
  const [agentDatas, setAgentDatas] = useState([]);
  const [customerDatas, setCustomerDatas] = useState([]);

  const [logDatas, setLogDatas] = useState([]);

  useEffect(() => { 
    const getUserData = async () => {
      await onAuthStateChanged(auth, (user) => { 
        if(user){
          onValue(ref(database, '/admin/' + auth.currentUser.uid), (snap) => {
          setAdminName(snap.val().adminName);
          setAdminEmail(snap.val().adminEmail);
      }); 
            }
            else {
              window.location.href = '/'; 
            }
          })
     }
       getUserData(); 
  
  },[]);

  useEffect(() => { 
    const getSideData = async () => {
      let sidebar = document.querySelector(".sidebar");
      let sidebarBtn = document.querySelector(".sidebarBtn");
      sidebarBtn.onclick = function() {
        sidebar.classList.toggle("active");
        if(sidebar.classList.contains("active")){
        sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
      }else
        sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
      }
    }
     getSideData(); 

},[]); 

useEffect(() => { 
  const getUserData = async () => {

      const starCountRef = ref(database, 'products/');
      onValue(starCountRef, async snapshot => {
          let returnArray = [];
       await snapshot.forEach(element => {
          var item = {};
          item.productId = element.val().productId;
          item.status = element.val().status;
          returnArray.push(item);
      });
   //   console.log(returnArray);
      setProductDatas(returnArray);
       
      });         
   }
     getUserData(); 

},[]); 

useEffect(() => { 
  const getUserData = async () => {

      const starCountRef = ref(database, 'agent/');
      onValue(starCountRef, async snapshot => {
          let returnArray = [];
       await snapshot.forEach(element => {
          var item = {};
          item.agentName = element.val().agentName;
          item.agentImage = element.val().agentImage;
          item.agentId = element.val().agentId;
          item.addressLine = element.val().addressLine;
          item.addressLine1 = element.val().addressLine1;
          item.city = element.val().city;
          item.pinCode = element.val().pinCode;
          item.state = element.val().state;
          item.status = element.val().status;
          item.stateCode = element.val().stateCode;
          item.phoneNumber = element.val().phoneNumber;
          returnArray.push(item);
        });
        console.log(returnArray);
        setAgentDatas(returnArray);
      });         
   }
     getUserData(); 

},[]); 

useEffect(() => { 
  const getUserData = async () => {

      const starCountRef = ref(database, 'customer/');
      onValue(starCountRef, async snapshot => {
          let returnArray = [];
       await snapshot.forEach(element => {
          var item = {};
          item.agentName = element.val().agentName;
          item.shopName = element.val().shopName;
          item.shopImage = element.val().shopImage;
          item.customerId = element.val().customerId;
          item.customerType = element.val().customerType;
          item.customerName = element.val().customerName;
          item.addressLine = element.val().addressLine;
          item.addressLine1 = element.val().addressLine1;
          item.locality = element.val().locality;
          item.city = element.val().city;
          item.pinCode = element.val().pinCode;
          item.commAddressLine = element.val().commAddressLine;
          item.commAddressLine1 = element.val().commAddressLine1;
          item.commCity = element.val().commCity;
          item.commPinCode = element.val().commPinCode;
          item.gstNumber = element.val().gstNumber;
          item.state = element.val().state;
          item.status = element.val().status;
          item.stateCode = element.val().stateCode;
          item.profileImage = element.val().profileImage;
          item.phoneNumber = element.val().phoneNumber;
          item.landlineNumber = element.val().landlineNumber;
          returnArray.push(item);
        });
        console.log(returnArray);
        setCustomerDatas(returnArray);
      });         
   }
     getUserData(); 

},[]); 

useEffect(() => { 
  const getUserData = async () => {

      const starCountRef = ref(database, 'orders/');
      onValue(starCountRef, async snapshot => {
          let returnArray = [];
       await snapshot.forEach(element => {
          var item = {};          
          item.customerId = element.val().customerId;
          item.orderId = element.val().orderId;
          returnArray.push(item);
        });
        setOrderDatas(returnArray);
      });         
   }
     getUserData(); 

},[]); 

useEffect(() => { 
  const getUserData = async () => {

      const starCountRef = ref(database, 'logs/enquiredCustomer');
      onValue(starCountRef, async snapshot => {
          let returnArray = [];
       await snapshot.forEach(element => {
          var item = {};
          item.keys = element.key;
          item.phoneNumber = element.val().phoneNumber;
          item.timestamp = element.val().timestamp;
          item.contacted = element.val().contacted;
          item.contactedTimestamp = element.val().contactedTimestamp;
          returnArray.push(item);
      });
      console.table(returnArray);
      setLogDatas(returnArray);
       
      });         
   }
     getUserData(); 

},[]); 

let logOut = e => {
  signOut(auth);
   window.location.href = '/';
   }

   let editCustomer = (data, data1) => {
    update(ref(database, 'customer/' + data), {
status: "Active"
    }).then(() => {
      update(ref(database, 'users/' + data1 + "/"), {
        status: "Active"
      }).then(()=>{
alert('Customer is activated successfully.');
//window.location.reload();
});
    });
   }

   let editAgent = (data, data1) => {
    update(ref(database, 'agent/' + data), {
status: "Active"
    }).then(() => {
      update(ref(database, 'users/' + data1 + "/"), {
        status: "Active"
      }).then(()=>{
alert('Agent is activated successfully.');
//window.location.reload();
});
    });
   }

   let dateFormatsss = (date) => {
    var data = moment.unix(date).format("DD-MM-YYYY HH:MM:SS");
    return data;
  }

  let enquiredCustomerUpdate = (data) => {
    update(ref(database, 'logs/enquiredCustomer/' + data), {
contacted: "Yes",
contactedTimestamp: moment().unix()
    }).then(()=>{
alert('Customer is enquired successfully.');
});
    }

    return (
      <section>
      <div className="sidebar">
     
      <div className="logo-details">&nbsp;
      <img src={require("./images/logoF.png")} style={{width: '50px', height: '50px'}} alt=""/> &nbsp;
      <img src={require("./images/elge.png")} style={{width: '150px', height: '80px'}} alt=""/>
      </div>
        <ul className="nav-links">
          <li>
            <a className="active">
              <i className='bx bx-grid-alt' ></i>
              <span className="links_name">Dashboard</span>
            </a>
          </li>
          <li>
          <Link to={"/category"}>
            <a>
              <i className='bx bx-align-justify' ></i>
              <span className="links_name">Manage Category</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/brand"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Brand</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/pack"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Pack</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageAgents"}>
            <a>
              <i className='bx bx-user-pin' ></i>
              <span className="links_name">Manage Agents</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/viewProduct"}>
            <a>
              <i className='bx bx-box' ></i>
              <span className="links_name">Manage Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/createProduct"}>
            <a>
            <i className='bx bx-pie-chart-alt-2' ></i>
              <span className="links_name">Create Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageCustomer"}>
            <a >
              <i className='bx bx-user' ></i>
              <span className="links_name">Manage Customer</span>
            </a>
            </Link>
          </li>
          <li>     
          <Link to={"/createCustomer"}>   
            <a>
            <i className='bx bx-user-plus' ></i>
              <span className="links_name">Create Customer</span>
            </a> 
            </Link>         
          </li>
          <li>
          <Link to={"/orders"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Orders</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/settings"}>
            <a>
              <i className='bx bx-cog' ></i>
              <span className="links_name">Settings</span>
            </a>
            </Link>
          </li>
          <li className="log_out">
          <a onClick={logOut}>
              <i className='bx bx-log-out'></i>
              <span className="links_name">Log out</span>
            </a>
          </li>
        </ul>
    </div>

    <section className="home-section">
      <nav>
        <div className="sidebar-button">
          <i className='bx bx-menu sidebarBtn'></i>
          <span className="dashboard">Dashboard</span>
        </div>
        <div className="profile-details">
        <img src={require("./images/male.png")} style={{width: '50px', height: '50px'}} alt=""/>
          <span className="admin_name">Admin</span>
          <i className='bx bx-chevron-down' ></i>
        </div>
      </nav>
  
      <div className="home-content">
        <div className="overview-boxes">
          <div className="box">
            <div className="right-side">
              <div className="box-topic">Orders</div>
              <div className="number">{orderDatas.length}</div>
              &emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;
            </div>
            <i className='bx bx-cart-alt cart'></i>
          </div>
          <div className="box">
            <div className="right-side">
              <div className="box-topic">Customers</div>
              <div className="number">{customerDatas.length}</div>
              &emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;
            </div>
            <i className='bx bxs-user cart two' ></i>
          </div>
          <div className="box">
            <div className="right-side">
              <div className="box-topic">Agents</div>
              <div className="number">{agentDatas.length}</div>
              &emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;
            </div>
            <i className='bx bxs-user-detail cart three' ></i>
          </div>
          <div className="box">
            <div className="right-side">
              <div className="box-topic">Products</div>
              <div className="number">{productDatas.length}</div>
              &emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;
            </div> 
            <i className='bx bxs-cart-download cart four' ></i>
          </div>
        </div>


        <div className='search_result_list my-2'>
            <h5 style={{color: "#1e40e9"}}>Activate Customer</h5>
            <div className="table-responsive mb-2" style={{borderRadius: '10px',border:'0.5px solid #ccc',borderCollapse: 'collapse'}}>
         <table className='table table-borderless mb-0' style={{border:'none', background: "white"}}>
         <thead className="text-center" style={{backgroundColor:'#e7f4fe', color:'grey'}}>
           <tr>
             <th style={{fontSize: '16px'}}>Shop Name</th>
             <th style={{fontSize: '16px'}}>Customer Type</th>
             <th style={{fontSize: '16px'}}>Locality</th>
             <th style={{fontSize: '16px'}}>City</th>
             <th style={{fontSize: '16px'}}>State</th>
             <th style={{fontSize: '16px'}}>GST Number</th>
             <th style={{fontSize: '16px'}}>Phone Number</th>
             <th style={{fontSize: '16px'}}>Agent Name</th>
             <th style={{fontSize: '16px'}}>Status</th>
             <th style={{fontSize: '16px'}}>Action</th>
           </tr>
           </thead>
           <tbody>
           {customerDatas.filter(x => x.status != "Active").length == 0?
           <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>No Customer to Activate</td>
           </tr>
:
<>
            {customerDatas.filter(x => x.status != "Active").map((persons,index) =>     
           <tr key={index}>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.shopName}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.customerType}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.locality}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.city}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.state}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.gstNumber}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.phoneNumber.substring(3)}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.agentName}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px', color: "red"}}>{persons.status}</td>
             <td style={{verticalAlign: 'middle'}}><button className="btn btn-primary" style={{borderRadius:'5px'}} onClick={() => editCustomer(persons.customerId, persons.phoneNumber)}>Activate</button>
                     </td>
           </tr>
          )}
          </>
    }
</tbody>
</table>
       </div>
<br></br>
       <h5 style={{color: "#1e40e9"}}>Activate Agent</h5>
            <div className="table-responsive mb-2" style={{borderRadius: '10px',border:'0.5px solid #ccc',borderCollapse: 'collapse'}}>
         <table className='table table-borderless mb-0' style={{border:'none', background: "white"}}>
         <thead className="text-center" style={{backgroundColor:'#e7f4fe', color:'grey'}}>
           <tr>
             <th style={{fontSize: '16px'}}>Agent Name</th>
             <th style={{fontSize: '16px'}}>City</th>
             <th style={{fontSize: '16px'}}>State</th>
             <th style={{fontSize: '16px'}}>Phone Number</th>
             <th style={{fontSize: '16px'}}>Status</th>
             <th style={{fontSize: '16px'}}>Action</th>
           </tr>
           </thead>
           <tbody>
           {agentDatas.filter(x => x.status != "Active").length == 0?
           <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>No Agent to Activate</td>
           </tr>
:
<>
            {agentDatas.filter(x => x.status != "Active").map((persons,index) =>     
           <tr key={index}>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.agentName}</td>
            <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.city}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.state}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.phoneNumber.substring(3)}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px', color: "red"}}>{persons.status}</td>
             <td style={{verticalAlign: 'middle'}}><button className="btn btn-primary" style={{borderRadius:'5px'}} onClick={() => editAgent(persons.agentId, persons.phoneNumber)}>Activate</button>
                     </td>
           </tr>
          )}
          </>
    }
</tbody>
</table>
       </div>
       <br></br>
       <h5 style={{color: "#1e40e9"}}>Enquired Customer</h5>
            <div className="table-responsive mb-2" style={{borderRadius: '10px',border:'0.5px solid #ccc',borderCollapse: 'collapse'}}>
         <table className='table table-borderless mb-0' style={{border:'none', background: "white"}}>
         <thead className="text-center" style={{backgroundColor:'#e7f4fe', color:'grey'}}>
           <tr>
             <th style={{fontSize: '16px'}}>Phone Number</th>
             <th style={{fontSize: '16px'}}>Timestamp</th>
             <th style={{fontSize: '16px'}}>Contacted</th>
             <th style={{fontSize: '16px'}}>Contacted Timestamp</th>
             <th style={{fontSize: '16px'}}>Action</th>
           </tr>
           </thead>
           <tbody>
           {logDatas.length == 0?
           <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>No Customers</td>
           </tr>
:
<>
            {logDatas.reverse().map((persons,index) =>     
           <tr key={index}>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.phoneNumber}</td>
            <td style={{verticalAlign: 'middle', fontSize: '14px'}}>
              {dateFormatsss(persons.timestamp)}
              </td>
             <td style={{verticalAlign: 'middle', fontSize: '14px', color: "red"}}>{persons.contacted}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>
              
              {persons.contactedTimestamp == ""?
              null
              :
              <>{dateFormatsss(persons.contactedTimestamp)}</>
            }</td>
             <td style={{verticalAlign: 'middle'}}>
             {persons.contacted == "Yes"?
              null
              :
              <button className="btn btn-primary" style={{borderRadius:'5px'}} onClick={() => enquiredCustomerUpdate(persons.keys)}>Called</button>
          }
                     </td>
           </tr>
          )}
          </>
    }
</tbody>
</table>
       </div>

        </div>


      </div>
    </section>
    </section>
        );

    }
    export default Dashboard;