import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage, storageRefs } from '../../config/firebase';
import { getDatabase, ref, push, set, onValue } from "firebase/database";
import { onAuthStateChanged, signOut } from "firebase/auth";
import './viewProduct.css';

function AgentOrder() {

    const [datas, setDatas] = useState([]);
    const [term, setTerm] = useState("");

    const [minEndDate, setMinEndDate] = useState(moment().subtract(1, "days").format("YYYY-MM-DD"));
    const [maxEndDate, setMaxEndDate] = useState(moment().format('YYYY-MM-DD'));

    const [minDate, setMinDate] = useState(moment().subtract(29, "days").format("YYYY-MM-DD"));

    const [maxDate, setMaxDate] = useState(moment().format('YYYY-MM-DD'));

    const [maxDate1, setMaxDate1] = useState(moment().add(1, "days").format('YYYY-MM-DD'));
    
      const locationPass = useLocation();

      const [adminEmail, setAdminEmail] = useState("");
      const [adminName, setAdminName] = useState("");
    
      useEffect(() => { 
        const getUserData = async () => {
          await onAuthStateChanged(auth, (user) => { 
            if(user){
              onValue(ref(database, '/admin/' + auth.currentUser.uid), (snap) => {
              setAdminName(snap.val().adminName);
              setAdminEmail(snap.val().adminEmail);
          }); 
                }
                else {
                  window.location.href = '/'; 
                }
              })
         }
           getUserData(); 
      
      },[]);

      useEffect(() => { 
        const getSideData = async () => {
          let sidebar = document.querySelector(".sidebar");
          let sidebarBtn = document.querySelector(".sidebarBtn");
          sidebarBtn.onclick = function() {
            sidebar.classList.toggle("active");
            if(sidebar.classList.contains("active")){
            sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
          }else
            sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
          }
        }
         getSideData(); 
    
    },[]); 

    useEffect(() => { 
      const getUserData = async () => {
        console.log(locationPass.state.agentIdPass)
        const starCountRef = ref(database, 'orders/');
        onValue(starCountRef, async snapshot => {
            let returnArray = [];
         await snapshot.forEach(element => {
            var item = {};          
            item.customerId = element.val().customerId;
            item.agentId = element.val().agentId;
            item.customerName = element.val().customerName;
            item.customerType = element.val().customerType;
            item.gstNumber = element.val().gstNumber;
            item.orderAt = element.val().orderAt;
            item.orderId = element.val().orderId;
            item.orderStatus = element.val().orderStatus;
            item.paymentStatus = element.val().paymentStatus;
            item.phoneNumber = element.val().phoneNumber;
            item.shopName = element.val().shopName;
            item.products = element.val().products;
            item.coverImage = element.val().coverImage;
            item.city = element.val().city;
            returnArray.push(item);
          });
          console.log(returnArray);
          setDatas(returnArray);
        });         
     }
       getUserData(); 

    },[]); 

    let dateFormatss = (date) => {
      console.log(moment().subtract(1, "days").format("YYYY-MM-DD"));
      var data = moment.unix(date).format("DD-MM-YYYY");
      return data;
    }

    const countTotal = (items) => items.reduce((acc, curr) => acc + curr.quantity * curr.priceWholesaler, 0);

    const countTotals = (items) => items.reduce((acc, curr) => parseInt(acc) + parseInt(curr.quantity), 0);

    let handleMax = (e) => {
      console.log(moment(e.target.value).format("YYYY-MM-DD"));
      setMaxDate(moment(e.target.value).format("YYYY-MM-DD"));
      setMaxDate1(moment(e.target.value).add(1, "days").format("YYYY-MM-DD"));
    }

    let handleMin = (e) => {
      console.log(moment(e.target.value).format("YYYY-MM-DD"));
      setMinDate(moment(e.target.value).format("YYYY-MM-DD"));
    }

    const print = () => {
      var divContents = document.getElementById("printareadiv").innerHTML; 
      var a = window.open('Orders', 'Order', 'height=500, width=500'); 
      a.document.write(`<html><head><title> ${locationPass.state.agentNamePass} - Customer Order Report</title>`); 
      a.document.write('</head><body>'); 
      a.document.write(`<h6>Agent ID: ${locationPass.state.agentIdPass} &emsp; From: ${moment(new Date(minDate)).format('DD-MM-YYYY')} To: ${moment(new Date(maxDate)).format('DD-MM-YYYY')}</h6>`);  
      a.document.write(divContents); 
      a.document.write('</body>'); 
      a.document.write('</html>'); 
      a.document.close();  
      a.focus();
      a.print();
      a.close();
    }

    let logOut = e => {
      signOut(auth);
       window.location.href = '/';
       }

    return (
      <section>
      <div className="sidebar">
     
      <div className="logo-details">&nbsp;
      <img src={require("./images/logoF.png")} style={{width: '50px', height: '50px'}} alt=""/> &nbsp;
      <img src={require("./images/elge.png")} style={{width: '150px', height: '80px'}} alt=""/>
      </div>
        <ul className="nav-links">
          <li>
          <Link to={"/dashboard"}>
            <a>
              <i className='bx bx-grid-alt' ></i>
              <span className="links_name">Dashboard</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/category"}>
            <a>
              <i className='bx bx-align-justify' ></i>
              <span className="links_name">Manage Category</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/brand"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Brand</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/pack"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Pack</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageAgents"}>
            <a className="active">
              <i className='bx bx-user-pin' ></i>
              <span className="links_name">Manage Agents</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/viewProduct"}>
            <a>
              <i className='bx bx-box' ></i>
              <span className="links_name">Manage Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/createProduct"}>
            <a>
            <i className='bx bx-pie-chart-alt-2' ></i>
              <span className="links_name">Create Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageCustomer"}>
            <a>
              <i className='bx bx-user' ></i>
              <span className="links_name">Manage Customer</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/createCustomer"}>
            <a>
            <i className='bx bx-user-plus' ></i>
              <span className="links_name">Create Customer</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/orders"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Orders</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/settings"}>
            <a>
              <i className='bx bx-cog' ></i>
              <span className="links_name">Settings</span>
            </a>
            </Link>
          </li>
          <li className="log_out">
          <a onClick={logOut}>
              <i className='bx bx-log-out'></i>
              <span className="links_name">Log out</span>
            </a>
          </li>
        </ul>
    </div>
    <section className="home-section">
      <nav>
        <div className="sidebar-button">
          <i className='bx bx-menu sidebarBtn'></i>
          <span className="dashboard">Agent Report</span>
        </div>
        <div className="search-box">
          <input type="text" placeholder="Search by Shop Name & GST & City..." onChange={(event) => { setTerm( event.target.value ); }} value={term}/>
          <i className='bx bx-search' ></i>
        </div>
        <div className="profile-details">
        <img src={require("./images/male.png")} style={{width: '50px', height: '50px'}} alt=""/>
          <span className="admin_name">Admin</span>
          <i className='bx bx-chevron-down' ></i>
        </div>
      </nav>
  
      <div className="home-content">

      <div className='search_result_list my-2'>

      <div className="row" style={{marginBottom: '10px'}}>
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-2">
<div className="rows">
<label style={{fontWeight:'600', fontSize:'14px', whiteSpace: 'nowrap'}}>Start Date<span style={{color:'red'}}>*</span> </label>
<input type="date" className="form-control input-field" style={{borderRadius:'10px', textIndent:'10px'}} value={minDate} /*defaultValue={moment(new Date(minDate)).format('DD-MM-YYYY')}*/ max={minEndDate} onChange={handleMin} />
</div>
</div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-2">
<div className="rows">
<label style={{fontWeight:'600', fontSize:'14px', whiteSpace: 'nowrap'}}>End Date<span style={{color:'red'}}>*</span> </label>
<input type="date" className="form-control input-field" style={{borderRadius:'10px', textIndent:'10px'}} value={maxDate} /*defaultValue={moment(new Date(maxDate)).format('DD-MM-YYYY')}*/ max={maxEndDate} onChange={handleMax} />
</div>
</div>

<div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-2">
<button type="button" className="btn btn-sm" onClick={() => print()} style={{backgroundColor: '#263579', color: '#fff', letterSpacing: '.3px',fontSize:'16px', float: 'right',marginRight: '25px'}}>Print</button>

</div>

</div>
    

      <div className="table-responsive mb-2" style={{borderRadius: '10px',border:'0.5px solid #ccc',borderCollapse: 'collapse'}}>
         <table className='table table-borderless mb-0' style={{border:'none', background: "white"}}>
         <thead className="text-center" style={{backgroundColor:'#e7f4fe', color:'grey'}}>
           <tr>
           <th style={{fontSize: '16px'}}>Order Date</th>
             <th style={{fontSize: '16px'}}>Web Order Id</th>
             <th style={{fontSize: '16px'}}>Shop Name</th>
             <th style={{fontSize: '16px'}}>City</th>
             <th style={{fontSize: '16px'}}>Order Status</th>
             <th style={{fontSize: '16px'}}>Payment Status</th>
               <th style={{fontSize: '16px'}}>Total Quantity</th>
             <th style={{fontSize: '16px'}}>Total Price</th>
           </tr>
           </thead>
           <tbody>
           {datas.filter(x => x.agentId == locationPass.state.agentIdPass).filter(
      user => (user.orderAt * 1000) >= Date.parse(minDate) && (user.orderAt * 1000) <= Date.parse(maxDate1)
    ).length == 0?
<tr>
<td></td>
<td></td>
<td></td>
<td></td> 
  <td>No data</td>
  <td></td>
  <td></td>
  <td></td>
</tr>
:
<>
            {datas.filter(x => x.agentId == locationPass.state.agentIdPass).filter(
      user => (user.orderAt * 1000) >= Date.parse(minDate) && (user.orderAt * 1000) <= Date.parse(maxDate1)
    ).reverse().map((persons,index) =>

<tr key={index}>
<td style={{verticalAlign: 'middle', fontSize: '14px'}}>{dateFormatss(persons.orderAt)}</td>
<td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.orderId}</td>
<td style={{verticalAlign: 'middle', fontSize: '14px', whiteSpace: 'nowrap'}}>{persons.shopName}</td>
<td style={{verticalAlign: 'middle', fontSize: '14px', whiteSpace: 'nowrap'}}>{persons.city}</td>
<td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.orderStatus}

  </td>
<td style={{verticalAlign: 'middle', fontSize: '14px'}}>
 {persons.paymentStatus}
  </td>
<td style={{verticalAlign: 'middle', fontSize: '14px'}}>{countTotals(Object.values(persons.products))}</td>
<td style={{verticalAlign: 'middle', fontSize: '14px'}}>₹{countTotal(Object.values(persons.products))}</td>
</tr>
)}
</>
            }
</tbody>
</table>
       </div>

       <div id="printareadiv" name='printareadiv' style={{display: 'none'}}>
              <div style={{height: '99%', width: '99%'}}>
           {/*}   <h5 className="modal-title text-center">{orderIdModal} - Order Details</h5>*/}
              <div className="mb-2">
         <table className='mb-0' style={{background: "white", border: '1px solid black', width: '100%', borderCollapse: 'collapse', borderRadius: '10px'}}>
         <thead className="text-center" style={{background: "white"}}>
           <tr>
             <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>Order Date</th>
             <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>Web Order Id</th>
             <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>Shop Name</th>    
           <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>City</th>
             <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>Order Status</th>
             <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>Payment Status</th>
             <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>Total Quantity</th>
             <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>Total Price</th>
           </tr>
           </thead>
           <tbody style={{background: "white"}}>

           {datas.filter(x => x.agentId == locationPass.state.agentIdPass).filter(
      user => (user.orderAt * 1000) >= Date.parse(minDate) && (user.orderAt * 1000) <= Date.parse(maxDate)
    ).reverse().map((persons,index) =>

<tr key={index}>
<td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>{dateFormatss(persons.orderAt)}</td>
<td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>{persons.orderId}</td>
<td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>{persons.shopName}</td>
<td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>{persons.city}</td>
<td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>{persons.orderStatus}</td>
<td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>{persons.paymentStatus}</td>
<td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>{countTotals(Object.values(persons.products))}</td>
<td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>₹{countTotal(Object.values(persons.products))}</td>
</tr>
)}

</tbody>
</table>
</div>
              </div>
              </div>

          </div>
          </div>

        </section> 
</section>
        );

    }
    export default AgentOrder;