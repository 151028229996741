import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage, storageRefs } from '../../config/firebase';
import { getDatabase, ref, push, set, orderByKey, limitToLast, onValue } from "firebase/database";
import { getDownloadURL, uploadBytesResumable, uploadBytes, listAll, list,} from "firebase/storage";
import { onAuthStateChanged, signOut } from "firebase/auth";

function CreateAgent() {

  const [agentId, setAgentId] = useState("");
  const [agentName, setAgentName] = useState("");
    const [addressLine, setAddressLine] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [city, setCity] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [state, setState] = useState("");
    const [stateCode, setStateCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [agentImage, setAgentImage] = useState(""); 
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState("Active"); 
    const [adminEmail, setAdminEmail] = useState("");
    const [adminName, setAdminName] = useState("");

    const [phoneDatas, setPhoneDatas] = useState([]);

    const [submitCheck, setSubmitCheck] = useState(false);
  
    useEffect(() => { 
      const getUserData = async () => {
        await onAuthStateChanged(auth, (user) => { 
          if(user){
            onValue(ref(database, '/admin/' + auth.currentUser.uid), (snap) => {
            setAdminName(snap.val().adminName);
            setAdminEmail(snap.val().adminEmail);
        }); 
              }
              else {
                window.location.href = '/'; 
              }
            })
       }
         getUserData(); 
    
    },[]);

    useEffect(() => { 
      const getSideData = async () => {
        let sidebar = document.querySelector(".sidebar");
        let sidebarBtn = document.querySelector(".sidebarBtn");
        sidebarBtn.onclick = function() {
          sidebar.classList.toggle("active");
          if(sidebar.classList.contains("active")){
          sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
        }else
          sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
        }
      }
       getSideData(); 
  
  },[]); 

  useEffect(() => { 
    const getUserData2 = async () => {

      const starCountRef = ref(database, 'users/');
      onValue(starCountRef, async snapshot => {
        if (snapshot.exists()) {
        let returnArray2 = [];
        snapshot.forEach((childSnapshot) => {
          returnArray2.push(childSnapshot.key);
        });
       console.log(returnArray2);
       setPhoneDatas(returnArray2);
  }
  else {
    setPhoneDatas([]);
  }
      });         
   }
     getUserData2(); 

},[]); 

    useEffect(() => { 
      const getUserData = async () => {

        const starCountRef = ref(database, 'agent/');
        onValue(starCountRef, async snapshot => {
          if (snapshot.exists()) {
          let returnArray = [];
          snapshot.forEach((childSnapshot) => {
            returnArray.push(childSnapshot.key);
          });
          let output = returnArray.pop();
let output1 = output.slice(-4);
let output2 = parseInt(output1) + parseInt(1);
let output3 = "";
if(output2.toString().length == 1){
output3 = 'ELETNAG' + '000' + output2;
}
else if(output2.toString().length == 2){
  output3 = 'ELETNAG' + '00' + output2;
  }
  else if(output2.toString().length == 3){
    output3 = 'ELETNAG' + '0' + output2;
    }
    else{
      output3 = 'ELETNAG' + output2;
      }
      console.log(snapshot.val());
setAgentId(output3);
    }
    else {
      setAgentId('ELETNAG0001');
      console.log("No data available");
    }
        });         
     }
       getUserData(); 

},[]); 

let handleImageChange1 = (e) => {
  e.preventDefault();
  let file = e.target.files[0];              
   let name = moment().unix()+file.name;
   let extFile = name.split('.').pop();
   if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
   const storageRef = storageRefs(storage, `profile/${name}`);
   const uploadTask = uploadBytesResumable(storageRef, file);

   uploadTask.on("state_changed",
     (snapshot) => {
       const progresspercent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
       setProgress(progresspercent);
     },
     (error) => {
       console.log(error);
     },
     () => {
       getDownloadURL(storageRef).then((downloadURL) => {
        console.log(downloadURL);
        setAgentImage(downloadURL);
       });
     }
   );
  }
  else{
alert('Please select jpg, jpeg and png file only.');
document.getElementById("filesAgent").value = "";
  }
}

    let submit = e => {
        e.preventDefault();
        setSubmitCheck(true);
if(agentName == ""){
          alert("Please enter agent name.");
          setSubmitCheck(false);
        }
else if(phoneNumber == ""){
  alert("Please enter phone number.");
  setSubmitCheck(false);
}
else if(phoneNumber.length < 10){
  alert("Please enter 10 digit phone number.");
  setSubmitCheck(false);
}
else if(addressLine == ""){
  alert("Please enter address.");
  setSubmitCheck(false);
}
else if(city == ""){
  alert("Please enter city.");
  setSubmitCheck(false);
}
else if(pinCode == ""){
  alert("Please enter pincode.");
  setSubmitCheck(false);
}
else if(state == ""){
  alert("Please enter state.");
  setSubmitCheck(false);
}
else if(stateCode == ""){
  alert("Please enter state code.");
  setSubmitCheck(false);
}
else{
  if(phoneDatas.indexOf(`+91${phoneNumber}`)!==-1){
    alert("Phone number is already in use.");
    setSubmitCheck(false);
      }
      else  { 
        if (window.confirm(`Please confirm phone number ${phoneNumber}. It is not editable?`)){
          set(ref(database, 'agent/' + agentId), {
            agentId: agentId,
  agentName: agentName,
  agentImage: agentImage,
  phoneNumber: "+91" + phoneNumber,
  addressLine: addressLine,
  addressLine1: addressLine1,
  city: city,
  pinCode: pinCode,
  state: state,
  stateCode: stateCode,
  status: status
          }).then(() => {
            set(ref(database, 'users/' + "+91" + phoneNumber + "/"), {
              id: agentId,
              phoneNumber: "+91" + phoneNumber,
              role: "Agent",
              status: status
            }).then(() => {
  alert('Agent is created successfully.');
  setSubmitCheck(false);
  window.location.reload();
          });
        });
        }
        else{
          setSubmitCheck(false);
          return false;
        } 
} 
    }
  }

    let reset = e => {
        e.preventDefault();
        window.location.reload();
    }

    let logOut = e => {
      signOut(auth);
       window.location.href = '/';
       }

    return (
      <section>
      <div className="sidebar">
     
      <div className="logo-details">&nbsp;
      <img src={require("./images/logoF.png")} style={{width: '50px', height: '50px'}} alt=""/> &nbsp;
      <img src={require("./images/elge.png")} style={{width: '150px', height: '80px'}} alt=""/>
      </div>
        <ul className="nav-links">
          <li>
          <Link to={"/dashboard"}>
            <a>
              <i className='bx bx-grid-alt' ></i>
              <span className="links_name">Dashboard</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/category"}>
            <a>
              <i className='bx bx-align-justify' ></i>
              <span className="links_name">Manage Category</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/brand"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Brand</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/pack"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Pack</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageAgents"}>
            <a className="active">
              <i className='bx bx-user-pin' ></i>
              <span className="links_name">Manage Agents</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/viewProduct"}>
            <a>
              <i className='bx bx-box' ></i>
              <span className="links_name">Manage Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/createProduct"}>
            <a>
            <i className='bx bx-pie-chart-alt-2' ></i>
              <span className="links_name">Create Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageCustomer"}>
            <a >
              <i className='bx bx-user' ></i>
              <span className="links_name">Manage Customer</span>
            </a>
            </Link>
          </li>
          <li>    
          <Link to={"/createCustomer"}>    
            <a>
            <i className='bx bx-user-plus' ></i>
              <span className="links_name">Create Customer</span>
            </a> 
            </Link>         
          </li>
          <li>
          <Link to={"/orders"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Orders</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/settings"}>
            <a>
              <i className='bx bx-cog' ></i>
              <span className="links_name">Settings</span>
            </a>
            </Link>
          </li>
          <li className="log_out">
          <a onClick={logOut}>
              <i className='bx bx-log-out'></i>
              <span className="links_name">Log out</span>
            </a>
          </li>
        </ul>
    </div>
    <section className="home-section">
      <nav>
        <div className="sidebar-button">
          <i className='bx bx-menu sidebarBtn'></i>
          <span className="dashboard">Create Agent</span>
        </div>

        <div className="profile-details">
        <img src={require("./images/male.png")} style={{width: '50px', height: '50px'}} alt=""/>
          <span className="admin_name">Admin</span>
          <i className='bx bx-chevron-down' ></i>
        </div>
      </nav>
  
      <div className="home-content">
<section style={{overflow: 'auto', minHeight: '100%'}}>

<div className="admincreatedocview">

      <div className="container">
          <div className="superadmincreatedoc_details" 
          style={{marginTop:'10px', marginBottom:'20px', backgroundColor:'white', boxShadow:' 0px 0 5px rgba(0, 0, 0, 0.3)', borderRadius:'10px',
         }}>

            <div className="row" style={{display:'flex', justifyContent:'center', padding:'30px 50px'}}>
              <div className="">
                <div className="superadmincreatedoc_info">

                    <div className="row">

                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>AGENT NAME<span style={{color:'red'}}>*</span>&emsp; </label>
       
      <input type="text" className="form-control" id="agentName" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Agent Name" name="agentName" value={agentName} 
      onChange={(event) => { setAgentName( event.target.value ); }} required/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>PROFILE IMAGE</label>
       
      <input type="file" className="form-control" id="filesAgent" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      name="filesAgent" 
      onChange={handleImageChange1} required/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>PHONE NUMBER<span style={{color:'red'}}>*</span>&emsp; </label>
                        <div class="input-group mb-3">
  <div class="input-group-prepend">
    <span class="input-group-text" id="basic-addon1">+91</span>
  </div>
      <input type="text" className="form-control" id="phoneNumber" style={{textIndent:'10px', fontSize: '12px'}} 
      placeholder="Phone Number" name="phoneNumber" value={phoneNumber} 
      onChange={(event) => { setPhoneNumber( event.target.value ); }} required/>
      </div>
      </div>



      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>ADDRESS<span style={{color:'red'}}>*</span>&emsp;</label>

      <input type="text" className="form-control" id="addressLine" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', marginBottom: '10px'}} 
      placeholder="Address Line 1" name="addressLine" value={addressLine} 
      onChange={(event) => { setAddressLine( event.target.value ); }} required/> 

<input type="text" className="form-control" id="addressLine1" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Address Line 2" name="addressLine1" value={addressLine1} 
      onChange={(event) => { setAddressLine1( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>CITY<span style={{color:'red'}}>*</span>&emsp; </label>

      <input type="text" className="form-control" id="city" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="City" name="city" value={city} 
      onChange={(event) => { setCity( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>PINCODE<span style={{color:'red'}}>*</span>&emsp; </label>

      <input type="text" className="form-control" id="pinCode" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="Pin Code" name="pinCode" value={pinCode} 
      onChange={(event) => { setPinCode( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>STATE<span style={{color:'red'}}>*</span>&emsp; </label>

      <input type="text" className="form-control" id="state" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="State" name="state" value={state} 
      onChange={(event) => { setState( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>STATE CODE<span style={{color:'red'}}>*</span>&emsp; </label>

      <input type="text" className="form-control" id="stateCode" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
      placeholder="State Code" name="stateCode" value={stateCode} 
      onChange={(event) => { setStateCode( event.target.value ); }} required/> 

      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>STATUS </label>
       
      <select className="form-control" id="status" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px'}} 
 name="status" value={status} 
      onChange={(event) => { setStatus( event.target.value ); }}>
<option value="Active">Active</option>
<option value="Inactive">Inactive</option>
          </select>
      
      </div>

      <hr/>              
    </div>

    <div className="superadmincreatedoc_savebtn" style={{textAlign:'center', marginTop:'10px'}}>
    {submitCheck == false?
                                  <>
                   <button className="btn btn-primary" style={{borderRadius:'5px'}} onClick={submit}>Save</button>&emsp;
                        <button className="btn btn-secondary ml-2" style={{borderRadius:'5px'}} onClick={reset}>Reset</button>
                       </>
                        :
                        <center>
                        <div className="loader"></div>
                        </center>
              }   
                   </div>

      </div>
    </div>
    </div>

    </div>
    </div>
          
       
        </div> 
        </section> 
        </div>
        </section> 
</section>
        );

    }
    export default CreateAgent;