import React, { useState, useEffect } from 'react';
import { Link, Navigate, NavLink, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage } from '../../config/firebase';
import './login.css';
import { sendPasswordResetEmail, onAuthStateChanged } from "firebase/auth";

const d = new Date();
let year = d.getFullYear();

function ForgotPassword() {

  const [yearFetch, setYearFetch] = useState(year);

  const [email, setEmail] = useState("");
  const [validates, setvalidates] = useState(false);
  const [errorMessageAuth, setErrorMessageAuth] = useState('');
  const [emailValidation, setEmailValidation] = useState(false);
  const [emailMessage, setEmailMessage] = useState(false);

  const [buttonEnable, setButtonEnable] = useState(false);

const  navigate = useNavigate();

useEffect(() => { 
  const getUserData = async () => {
    await onAuthStateChanged(auth, (user) => { 
      if(user){
        console.log(user.uid);
          }
        })
   }
     getUserData(); 

},[]);

let validate = (text) => {
  console.log(text);
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ ;
  if(reg.test(text) === false)
  {
  setEmail(text);
  setvalidates(false);
  return false;
    }
  else {
    setEmail(text);
    setvalidates(true);
  }
  }

  let handleEmail = e => {
    validate(e.target.value);
    setEmailMessage(false);
    setEmailValidation(false);
    setErrorMessageAuth('');
  }

let submit = e => {
  if(email == ''){
    setEmailMessage(true);
  }
  else if(validates === false){
    setEmailValidation(true);
}
    else {
      sendPasswordResetEmail(auth, email)
      .then(function () {
        alert('Email sent to Your email account!!!');
          setButtonEnable(false);
          window.location.href = '/';
      }).catch(function (e) {
        setButtonEnable(false);
          console.log(e)
          setErrorMessageAuth(e)
      }) 
    }
}

let handleKeypress = e => {
  //it triggers by pressing the enter key
if (e.key == 'Enter') {
  submit();
}
};
 
return (
  <section className="signin">

    <a className="navbar-brand" style={{marginTop: '15px', marginLeft: '15px'}}>
    <img src={require("./images/logoF.png")} style={{width: '70px', height: '70px'}} alt=""/> &nbsp;
      <img src={require("./images/elge.png")} style={{width: '150px', height: '80px'}} alt=""/>
            </a>

  <div className="Doctor_login_page">
<div className="Doctor_login_page_logindetails">
<div className="container">
  <div className="row m-0">
  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center'}}>


      <div className="Doctor_login_pageform">
        <div className="title mb-4">
          <center>
          <h3 className="my-2">Forgot Password</h3>
          </center>
 
        </div>
        <div className="form">
          <div className="email my-3 d-flex rows">
                <i className="fa fa-envelope-o" aria-hidden="true"></i> 
                <input className="input-field" type="email" value={email} onChange={handleEmail} onKeyPress={handleKeypress} autoCapitalize="none" placeholder="Email Address" /*onChange={(event) => validate(event.target.value)}*/ required/>
            </div>
            <br></br>

{emailValidation == true?
 
 <div className='alert alert-danger alert-dismissible'>
         <button type='button' className='close' onClick={() => setEmailValidation(false)}>&times;</button>
<strong>Warning!</strong> Email is badly formatted.
</div>
:
null
}

{emailMessage == true?
     
 <div className='alert alert-danger alert-dismissible'>
         <button className='close' type='button' onClick={() => setEmailMessage(false)}>&times;</button>
<strong>Warning!</strong> Email is empty.
</div>

:
null
}

{errorMessageAuth == ''?
null    
:

<div className='alert alert-danger alert-dismissible'>
<button type='button' className='close' onClick={() => setErrorMessageAuth('')}>&times;</button>
{errorMessageAuth}
</div>
}
<div className="" style={{paddingBottom: '10px', float: 'right'}}>
                <a onClick={() => navigate("/")} className="font" style={{color:'gray', cursor: 'pointer'}}>Back to Login</a>
             </div>
          <div className="button my-2">
          <center>
            <button disabled={buttonEnable} onClick={submit} style={{width: "200px"}}>Send Email <i className="fa fa-long-arrow-right text-white" aria-hidden="true"></i></button>
            </center>
          </div>
        </div>
      </div>

    
  </div>
  </div>
</div>
</div>

<div className="footer-section copyrights-text">

<p className="" style={{color: '#000'}}>&emsp;© Copyright 2021 - {yearFetch}, Elge Enterprises | All Rights Reserved    <small className="" style={{color: '#000', float: 'right', marginRight: '35px'}}>Telth Systems Private limited.</small></p>
        
        </div>
</div>      
  </section>
    );
}
export default ForgotPassword;
