import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import './viewProduct.css';
import { auth, database, storage } from '../../config/firebase';
import { reauthenticateWithCredential, onAuthStateChanged, EmailAuthProvider, updatePassword, signOut } from "firebase/auth";
import { ref, onValue,update} from "firebase/database";

let userId;

function Settings() {

  const [salutation, setSalutation] = useState("Mr.");
const [userName, setUserName] = useState("");
const [email, setEmail] = useState("");

const [oldPassword, setOldPassword] = useState("");
const [oldPasswordEmpty, setOldPasswordEmpty] = useState(false);

const [newPassword, setNewPassword] = useState("");
const [newPasswordEmpty, setNewPasswordEmpty] = useState(false);

const [passwordMessage, setPasswordMessage] = useState(false);
const [passwordMessageError, setPasswordMessageError] = useState(false);

const [buttonEnable, setButtonEnable] = useState(false);

const [primaryColor, setPrimaryColor] = useState("#1e40e9");
const [secondaryColor, setSecondaryColor] = useState("#3B59EC");
const [tertiaryColor, setTertiaryColor] = useState("#1e97f4");
const [buttonColor, setButtonColor] = useState("#100dbf");

const [submitCheck, setSubmitCheck] = useState(false);
const [submitCheck1, setSubmitCheck1] = useState(false);

const [adminName, setAdminName] = useState("");

  useEffect(() => { 
    const getSideData = async () => {
      let sidebar = document.querySelector(".sidebar");
      let sidebarBtn = document.querySelector(".sidebarBtn");
      sidebarBtn.onclick = function() {
        sidebar.classList.toggle("active");
        if(sidebar.classList.contains("active")){
        sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
      }else
        sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
      }
    }
     getSideData(); 

},[]); 

useEffect(() => { 
  const getUserData = async () => {
    await onAuthStateChanged(auth, (user) => {
      if(user) { 
              userId = auth.currentUser.uid;
             console.log(userId);
              onValue(ref(database, '/admin/' + auth.currentUser.uid), (snap) => {
                console.log(snap.val());
              setAdminName(snap.val().adminName);
              setUserName(snap.val().adminName);
              setEmail(snap.val().adminEmail);
          });     
          onValue(ref(database, '/appColor/'), (snap) => {
            console.log(snap.val().primaryColor);
            setPrimaryColor(snap.val().primaryColor);
            setSecondaryColor(snap.val().secondaryColor);
            setTertiaryColor(snap.val().tertiaryColor);
            setButtonColor(snap.val().buttonColor);
      });       

          }
          else {
          window.location.href = '/'; 
          }
        })
   }
     getUserData(); 
},[]);

let submit = e => {
  e.preventDefault();
  setSubmitCheck(true);
  if(oldPassword == ''){
    setOldPasswordEmpty(true);
    setSubmitCheck(false);
    }
    else if(newPassword == ''){
      setNewPasswordEmpty(true);
      setSubmitCheck(false);
    }
    else{
      e.preventDefault();
      setButtonEnable(true);
    console.log(email);
    console.log(oldPassword);
      const user = auth.currentUser;
      var credential = EmailAuthProvider.credential(
        email,
        oldPassword
      ); 
      reauthenticateWithCredential(user, credential).then(function() {
        updatePassword(user, newPassword).then(() => {
    setPasswordMessage(true);
    setButtonEnable(false);
    setSubmitCheck(false);
        })
      }).catch(function(error) {
      setPasswordMessageError(true);
      setButtonEnable(false);
      setSubmitCheck(false);
      });   
    }
}

let submit1 = e => {
  e.preventDefault();
  setSubmitCheck1(true);
      e.preventDefault();
      update(ref(database, 'appColor/'), {
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
tertiaryColor: tertiaryColor,
buttonColor: buttonColor
      }).then(() => {
        alert('Color details is updated successfully.');
        setSubmitCheck1(false);
});
}

let handlePassword = e => {
  setOldPassword( e.target.value );
  setPasswordMessage(false);
  setPasswordMessageError(false);
  setOldPasswordEmpty(false);
}

let handleNewPassword = e => {
  setNewPassword( e.target.value );
  setNewPasswordEmpty(false);
}

let logOut = e => {
 signOut(auth);
  window.location.href = '/';
  }

    return (
      <section>
      <div className="sidebar">
     
      <div className="logo-details">&nbsp;
      <img src={require("./images/logoF.png")} style={{width: '50px', height: '50px'}} alt=""/> &nbsp;
      <img src={require("./images/elge.png")} style={{width: '150px', height: '80px'}} alt=""/>
      </div>
        <ul className="nav-links">
          <li>
          <Link to={"/dashboard"}>
            <a>
              <i className='bx bx-grid-alt' ></i>
              <span className="links_name">Dashboard</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/category"}>
            <a>
              <i className='bx bx-align-justify' ></i>
              <span className="links_name">Manage Category</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/brand"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Brand</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/pack"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Pack</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageAgents"}>
            <a>
              <i className='bx bx-user-pin' ></i>
              <span className="links_name">Manage Agents</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/viewProduct"}>
            <a>
              <i className='bx bx-box' ></i>
              <span className="links_name">Manage Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/createProduct"}>
            <a>
            <i className='bx bx-pie-chart-alt-2' ></i>
              <span className="links_name">Create Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageCustomer"}>
            <a >
              <i className='bx bx-user' ></i>
              <span className="links_name">Manage Customer</span>
            </a>
            </Link>
          </li>
          <li>     
          <Link to={"/createCustomer"}>   
            <a>
            <i className='bx bx-user-plus' ></i>
              <span className="links_name">Create Customer</span>
            </a> 
            </Link>         
          </li>
          <li>
          <Link to={"/orders"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Orders</span>
            </a>
            </Link>
          </li>
          <li>
            <a className="active">
              <i className='bx bx-cog' ></i>
              <span className="links_name">Settings</span>
            </a>
          </li>
          <li className="log_out">
            <a onClick={logOut}>
              <i className='bx bx-log-out'></i>
              <span className="links_name">Log out</span>
            </a>
          </li>
        </ul>
    </div>
    <section className="home-section">
      <nav>
        <div className="sidebar-button">
          <i className='bx bx-menu sidebarBtn'></i>
          <span className="dashboard">Settings</span>
        </div>

        <div className="profile-details">
        <img src={require("./images/male.png")} style={{width: '50px', height: '50px'}} alt=""/>
          <span className="admin_name">Admin</span>
          <i className='bx bx-chevron-down' ></i>
        </div>
      </nav>
  
      <div className="home-content">

      <div className="admincreatedocview">

<div className="container">
    <div className="superadmincreatedoc_details" 
    style={{marginTop:'10px', marginBottom:'20px', backgroundColor:'white', boxShadow:' 0px 0 5px rgba(0, 0, 0, 0.3)', borderRadius:'10px',
   }}>

      <div className="row" style={{display:'flex', justifyContent:'center', padding:'30px 50px'}}>
        <div className="">
          <div className="superadmincreatedoc_info">

      <div className="row">



<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px', whiteSpace: 'nowrap'}}>User Name</label>
                        <input type="text" className="form-control input-field" id="userName" style={{borderRadius:'10px', textIndent:'10px', backgroundColor: '#fff'}}
      placeholder="User Name" name="userName" value={userName} readOnly/>

</div>


<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px', whiteSpace: 'nowrap'}}>Email&nbsp;</label>
                        <input type="email" maxLength="50" className="form-control input-field" id="email" style={{borderRadius:'10px', textIndent:'10px', backgroundColor: '#fff'}}
      placeholder="Email" name="email" value={email} autoCapitalize="none" readOnly/>
</div>




<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                        <label style={{fontWeight:'600', fontSize:'14px', whiteSpace: 'nowrap'}}>Current Password&nbsp;<span style={{color:'red'}}>*</span></label>
                   
                        <input  maxLength="10" className="form-control input-field" id="oldPassword" style={{borderRadius:'10px', textIndent:'10px', backgroundColor: '#fff'}}
      placeholder="Current Password" name="oldPassword"  value={oldPassword} onChange={handlePassword}/>
</div>

<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                        <label style={{fontWeight:'600', fontSize:'14px', whiteSpace: 'nowrap'}}>New Password&nbsp;<span style={{color:'red'}}>*</span></label>
                   
                        <input type="text" maxLength="10" className="form-control input-field" id="newPassword" style={{borderRadius:'10px', textIndent:'10px', backgroundColor: '#fff'}}
      placeholder="New Password" name="newPassword"  value={newPassword} onChange={handleNewPassword}/>
          </div>   

</div>
</div>
{passwordMessage == false?
   null    
:

<div className='alert alert-success alert-dismissible'>
    <button type='button' className='close' onClick={() => setPasswordMessage(false)}>&times;</button>
    <strong>Success!</strong> Password is updated.
</div>
}

{passwordMessageError == false?
   null    
:

<div className='alert alert-danger alert-dismissible'>
    <button type='button' className='close' onClick={() => setPasswordMessageError(false)}>&times;</button>
    <strong>Warning!</strong> Current Password is incorrect.
</div>
}

{oldPasswordEmpty == false?
   null    
:

<div className='alert alert-danger alert-dismissible'>
    <button type='button' className='close' onClick={() => setOldPasswordEmpty(false)}>&times;</button>
    <strong>Warning!</strong> Current Password is empty.
</div>
}

{newPasswordEmpty == false?
   null    
:

<div className='alert alert-danger alert-dismissible'>
    <button type='button' className='close' onClick={() => setNewPasswordEmpty(false)}>&times;</button>
    <strong>Warning!</strong> New Password is empty.
</div>
}
<hr/>
<div style={{textAlign:'center', marginTop:'10px'}}>
{submitCheck == false?
                                  <>
                   <button className="btn btn-primary" disabled={buttonEnable} style={{borderRadius:'5px'}} onClick={submit}>Update</button>&emsp;
                   </>
                        :
                        <center>
                        <div className="loader"></div>
                        </center>
              }
                   </div>

                </div>

                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                        <label style={{fontWeight:'600', fontSize:'14px', whiteSpace: 'nowrap'}}>Primary Color&nbsp;<span style={{color:'red'}}>*</span></label>
                   
                        <input type="color" className="form-control" id="primaryColor" style={{borderRadius:'10px', textIndent:'10px', backgroundColor: '#fff'}}
      name="primaryColor" value={primaryColor} onChange={(event) => { setPrimaryColor( event.target.value ); }} required/>
          </div>  

          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                        <label style={{fontWeight:'600', fontSize:'14px', whiteSpace: 'nowrap'}}>Secondary Color&nbsp;<span style={{color:'red'}}>*</span></label>
                   
                        <input type="color" className="form-control" id="secondaryColor" style={{borderRadius:'10px', textIndent:'10px', backgroundColor: '#fff'}}
      name="secondaryColor" value={secondaryColor} onChange={(event) => { setSecondaryColor( event.target.value ); }} required/>
          </div>   

                          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                        <label style={{fontWeight:'600', fontSize:'14px', whiteSpace: 'nowrap'}}>Tertiary Color&nbsp;<span style={{color:'red'}}>*</span></label>
                   
                        <input type="color" className="form-control" id="tertiaryColor" style={{borderRadius:'10px', textIndent:'10px', backgroundColor: '#fff'}}
      name="tertiaryColor" value={tertiaryColor} onChange={(event) => { setTertiaryColor( event.target.value ); }} required/>
          </div>  

                          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                        <label style={{fontWeight:'600', fontSize:'14px', whiteSpace: 'nowrap'}}>Button Color&nbsp;<span style={{color:'red'}}>*</span></label>
                   
                        <input type="color" className="form-control" id="buttonColor" style={{borderRadius:'10px', textIndent:'10px', backgroundColor: '#fff'}}
      name="buttonColor" value={buttonColor} onChange={(event) => { setButtonColor( event.target.value ); }} required/>
          </div>     

          <hr/>
<div style={{textAlign:'center', marginTop:'10px'}}>
{submitCheck1 == false?
                                  <>
                   <button className="btn btn-primary" style={{borderRadius:'5px'}} onClick={submit1}>Update</button>
                   </>
                        :
                        <center>
                        <div className="loader"></div>
                        </center>
              }
                   </div>

 </div>
 </div>
 </div>
 </div>

          </div>

        </section> 
</section>
        );

    }
    export default Settings;