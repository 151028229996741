import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage, storageRefs } from '../../config/firebase';
import { getDatabase, ref, push, set, child, onValue, update } from "firebase/database";
import { getDownloadURL, uploadBytesResumable, uploadBytes, listAll, list,} from "firebase/storage";
import { onAuthStateChanged, signOut } from "firebase/auth";
import './viewProduct.css';

function Pack() {

    const [datas, setDatas] = useState([]);
    const [term, setTerm] = useState("");

    const [packDatas, setPackDatas] = useState([]);
    const [categoryName, setCategoryName] = useState("");
    const [packName, setPackName] = useState("");
    const [packId, setPackId] = useState("");
    const [pricePer, setPricePer] = useState(""); 

    const [edit, setEdit] = useState(false); 

    const navigate = useNavigate();

    const [adminEmail, setAdminEmail] = useState("");
    const [adminName, setAdminName] = useState("");
    const [submitCheck, setSubmitCheck] = useState(false);
    useEffect(() => { 
      const getUserData = async () => {
        await onAuthStateChanged(auth, (user) => { 
          if(user){
            onValue(ref(database, '/admin/' + auth.currentUser.uid), (snap) => {
            setAdminName(snap.val().adminName);
            setAdminEmail(snap.val().adminEmail);
        }); 
              }
              else {
                window.location.href = '/'; 
              }
            })
       }
         getUserData(); 
    
    },[]);

      useEffect(() => { 
    const getSideData = async () => {
      let sidebar = document.querySelector(".sidebar");
      let sidebarBtn = document.querySelector(".sidebarBtn");
      sidebarBtn.onclick = function() {
        sidebar.classList.toggle("active");
        if(sidebar.classList.contains("active")){
        sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
      }else
        sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
      }
    }
     getSideData(); 

},[]);   

    useEffect(() => { 
        const getUserData = async () => {

            const starCountRef = ref(database, 'category/');
            onValue(starCountRef, async snapshot => {
                let returnArray = [];
             await snapshot.forEach(element => {
                var item = {};
                item.categoryName = element.val().categoryName;
                item.categoryId = element.val().categoryId;
                returnArray.push(item);
            console.log(returnArray);
            setDatas(returnArray);
              });

            });         
         }
           getUserData(); 

    },[]); 

    useEffect(() => { 
      const getUserData1 = async () => {

          const starCountRef = ref(database, 'pack/');
          onValue(starCountRef, async snapshot => {
              let returnArray = [];
           await snapshot.forEach(element => {
              var item = {};
              item.packName = element.val().packName;
              item.categoryName = element.val().categoryName;
              item.packId = element.val().packId;
              item.pricePer = element.val().pricePer;
              returnArray.push(item);
          console.log(returnArray);
          setPackDatas(returnArray);
            });

          });         
       }
         getUserData1(); 

  },[]); 

let handleCategory = (e) => {
alert(e.target.value);
alert(e.target.name);
  }

let submit = e => {
  e.preventDefault();
  setSubmitCheck(true);
  if(categoryName == ""){
    alert("Please enter category name.");
    setSubmitCheck(false);
  }
  else if(packName == ""){
    alert("Please enter pack name.");
    setSubmitCheck(false);
  }
  else if(pricePer == ""){
    alert("Please enter price per.");
    setSubmitCheck(false);
  }
else{
  const newPostKey = push(child(ref(database), 'pack')).key;

  const postData = {
    pricePer: pricePer,
    categoryName: categoryName,
    packName: packName,
    packId: newPostKey
  };

  const updates = {};
  updates['/pack/' + newPostKey] = postData;

  return update(ref(database), updates).then(() => {
    alert('Pack is created successfully.');
    setSubmitCheck(false);
    window.location.reload();
      });
}
}

let packEdit = (data) => {
  setEdit(true);
  window.scrollTo(0, 0);
  setPackId(data);
  let dataa = packDatas.find(x=>x.packId == data);
  setCategoryName(dataa.categoryName);
  setPackName(dataa.packName);
  setPricePer(dataa.pricePer)
     }

let reset = e => {
  e.preventDefault();
  window.location.reload();
}

let logOut = e => {
  signOut(auth);
   window.location.href = '/';
   }

   let submitEdit = e => {
    e.preventDefault();
    setSubmitCheck(true);
    if(categoryName == ""){
      alert("Please enter category name.");
      setSubmitCheck(false);
    }
    else if(packName == ""){
      alert("Please enter pack name.");
      setSubmitCheck(false);
    }
    else if(pricePer == ""){
      alert("Please enter price per.");
      setSubmitCheck(false);
    }
  else{
    update(ref(database, 'pack/' + packId), {
      pricePer: pricePer,
      categoryName: categoryName,
      packName: packName,
    }).then(() => {
      alert('brand is updated successfully.');
      setSubmitCheck(false);
      setEdit(false);
      setPackName("");
      setCategoryName(""); 
setPricePer("");
      setPackId("");
        });
  }
  }

    return (
      <section>
      <div className="sidebar">
     
      <div className="logo-details">&nbsp;
      <img src={require("./images/logoF.png")} style={{width: '50px', height: '50px'}} alt=""/> &nbsp;
      <img src={require("./images/elge.png")} style={{width: '150px', height: '80px'}} alt=""/>
      </div>
        <ul className="nav-links">
          <li>
          <Link to={"/dashboard"}>
            <a>
              <i className='bx bx-grid-alt' ></i>
              <span className="links_name">Dashboard</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/category"}>
            <a>
              <i className='bx bx-align-justify' ></i>
              <span className="links_name">Manage Category</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/brand"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Brand</span>
            </a>
            </Link>
          </li>
          <li>
            <a className="active">
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Pack</span>
            </a>
          </li>
          <li>
          <Link to={"/manageAgents"}>
            <a>
              <i className='bx bx-user-pin' ></i>
              <span className="links_name">Manage Agents</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/viewProduct"}>
            <a>
              <i className='bx bx-box' ></i>
              <span className="links_name">Manage Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/createProduct"}>
            <a>
            <i className='bx bx-pie-chart-alt-2' ></i>
              <span className="links_name">Create Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageCustomer"}>
            <a >
              <i className='bx bx-user' ></i>
              <span className="links_name">Manage Customer</span>
            </a>
            </Link>
          </li>
          <li>     
          <Link to={"/createCustomer"}>   
            <a>
            <i className='bx bx-user-plus' ></i>
              <span className="links_name">Create Customer</span>
            </a> 
            </Link>         
          </li>
          <li>
          <Link to={"/orders"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Orders</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/settings"}>
            <a>
              <i className='bx bx-cog' ></i>
              <span className="links_name">Settings</span>
            </a>
            </Link>
          </li>
          <li className="log_out">
          <a onClick={logOut}>
              <i className='bx bx-log-out'></i>
              <span className="links_name">Log out</span>
            </a>
          </li>
        </ul>
    </div>
    <section className="home-section">
      <nav>
        <div className="sidebar-button">
          <i className='bx bx-menu sidebarBtn'></i>
          <span className="dashboard">Manage Pack</span>
        </div>
        <div className="search-box">
          <input type="text" style={{fontSize: '14px'}} placeholder="Search by Category & Pack Name..." onChange={(event) => { setTerm( event.target.value ); }} value={term}/>
          <i className='bx bx-search' ></i>
        </div>
        <div className="profile-details">
        <img src={require("./images/male.png")} style={{width: '50px', height: '50px'}} alt=""/>
          <span className="admin_name">Admin</span>
          <i className='bx bx-chevron-down' ></i>
        </div>
      </nav>
  
      <div className="home-content">

      <section style={{overflow: 'auto', minHeight: '100%'}}>
      <div className="admincreatedocview">
      {edit == false?

      <div className="container">
          <div className="superadmincreatedoc_details" 
          style={{marginTop:'10px', marginBottom:'20px', backgroundColor:'white', boxShadow:' 0px 0 5px rgba(0, 0, 0, 0.3)', borderRadius:'10px',
         }}>

            <div className="row" style={{display:'flex', justifyContent:'center', padding:'30px 50px'}}>
            
                <div className="superadmincreatedoc_info">

                    <div className="row">

                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>CATEGORY</label>
       
                          <select className="form-control" id="categoryName" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
 name="categoryName" value={categoryName} onChange={(event) => { setCategoryName( event.target.value ); }}>
          <option>Choose Category</option>
          {datas.map((person, index) => (  
<option value={person.categoryName}>{person.categoryName}</option>
 ))}
          </select>
          </div>

                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>PACK NAME </label>
       
      <input type="text" className="form-control" id="packName" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      placeholder="Pack Name" name="packName" value={packName} 
      onChange={(event) => { setPackName( event.target.value ); }} required/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>PRICE PER </label>
       
      <input type="text" className="form-control" id="pricePer" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      placeholder="Price Per" name="pricePer" value={pricePer} 
      onChange={(event) => { setPricePer( event.target.value ); }} required/>
      
      </div>

      <hr/>              
    </div>

    <div className="superadmincreatedoc_savebtn" style={{textAlign:'center', marginTop:'10px'}}>
    {submitCheck == false?
                                  <>
                   <button className="btn btn-primary" style={{borderRadius:'5px'}} onClick={submit}>Save</button>&emsp;
                        <button className="btn btn-secondary ml-2" style={{borderRadius:'5px'}} onClick={reset}>Reset</button>
                        </>
                        :
                        <center>
                        <div className="loader"></div>
                        </center>
              }   
                   </div>

      </div>
    </div>

    </div>
    </div>
  
    :
    <div className="container">
    <div className="superadmincreatedoc_details" 
    style={{marginTop:'10px', marginBottom:'20px', backgroundColor:'white', boxShadow:' 0px 0 5px rgba(0, 0, 0, 0.3)', borderRadius:'10px',
   }}>

      <div className="row" style={{display:'flex', justifyContent:'center', padding:'30px 50px'}}>
      
          <div className="superadmincreatedoc_info">

              <div className="row">

              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <label style={{fontWeight:'600', fontSize:'14px'}}>CATEGORY</label>
 
                    <select className="form-control" id="categoryName" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
name="categoryName" value={categoryName} onChange={(event) => { setCategoryName( event.target.value ); }}>
    <option>Choose Category</option>
    {datas.map((person, index) => (  
<option value={person.categoryName}>{person.categoryName}</option>
))}
    </select>
    </div>

              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>PACK NAME </label>
 
<input type="text" className="form-control" id="packName" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
placeholder="Pack Name" name="packName" value={packName} 
onChange={(event) => { setPackName( event.target.value ); }} required/>

</div>

<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>PRICE PER </label>
 
<input type="text" className="form-control" id="pricePer" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
placeholder="Price Per" name="pricePer" value={pricePer} 
onChange={(event) => { setPricePer( event.target.value ); }} required/>

</div>

<hr/>              
</div>

<div className="superadmincreatedoc_savebtn" style={{textAlign:'center', marginTop:'10px'}}>
{submitCheck == false?
                                  <>
             <button className="btn btn-primary" style={{borderRadius:'5px'}} onClick={submitEdit}>Update</button>
                  </>
                        :
                        <center>
                        <div className="loader"></div>
                        </center>
              }  
             </div>

</div>
</div>

</div>
</div>
          }
          </div> 
        </section> 

      <div className='search_result_list my-2'>

            <div className="table-responsive mb-2" style={{borderRadius: '10px',border:'0.5px solid #ccc',borderCollapse: 'collapse'}}>
         <table className='table table-borderless mb-0' style={{border:'none', background: "white"}}>
         <thead className="text-center" style={{backgroundColor:'#e7f4fe', color:'grey'}}>
           <tr>
             <th style={{fontSize: '16px'}}>Category Name</th>
             <th style={{fontSize: '16px'}}>Pack Name</th>
             <th style={{fontSize: '16px'}}>Price Per</th>
             <th style={{fontSize: '16px'}}>Action</th>
           </tr>
           </thead>
           <tbody>
            {packDatas.length == 0?
            <tr>
            <td style={{verticalAlign: 'middle', fontSize: '14px'}}></td>
            <td style={{verticalAlign: 'middle', fontSize: '14px'}}></td>
            <td style={{verticalAlign: 'middle', fontSize: '14px', float: 'right'}}>No data</td>
            <td style={{verticalAlign: 'middle', fontSize: '14px'}}></td>
          </tr>
          :
          <>
            {packDatas.map((persons,index) =>
      
           <tr key={index}>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.categoryName}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.packName}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.pricePer}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}><i className="bx bx-edit" style={{fontSize: '24px'}} aria-hidden="true" onClick={() => packEdit(persons.packId)}></i> </td>
           </tr>
            
          )}
          </>
}
</tbody>
</table>
       </div>
        </div>

          </div>
       

        </section> 
</section>
        );

    }
    export default Pack;