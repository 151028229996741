import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage, storageRefs } from '../../config/firebase';
import { getDatabase, ref, push, set, onValue, update, child, remove} from "firebase/database";
import { getDownloadURL, uploadBytesResumable, uploadBytes, listAll, list,} from "firebase/storage";
import { onAuthStateChanged, signOut } from "firebase/auth";
import './viewProduct.css';

function CustomerOrder() {

  const [customerType, setCustomerType] = useState("Wholesaler");
  const [agentId, setAgentId] = useState("");
  const [agentName, setAgentName] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [shopName, setShopName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [addressLine, setAddressLine] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [city, setCity] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [commAddressLine, setCommAddressLine] = useState("");
    const [commAddressLine1, setCommAddressLine1] = useState("");
    const [commCity, setCommCity] = useState("");
    const [commPinCode, setCommPinCode] = useState("");
    const [gstNumber, setGstNumber] = useState("");
    const [state, setState] = useState("");
    const [stateCode, setStateCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");
    const [landlineNumber, setLandlineNumber] = useState("");
    const [shopImage, setShopImage] = useState("");   
    const [profileImage, setProfileImage] = useState(""); 

    const [datas, setDatas] = useState([]);

    const [dataOrders, setDataOrders] = useState([]);

    const [fileOrders, setFileOrders] = useState([]);
    const [orderIdFile, setOrderIdFile] = useState("");
  
    const [fileName, setFileName] = useState("");
    const [fileNames, setFileNames] = useState("");
    const [progress, setProgress] = useState(0);
    const [fileUpload, setFileUpload] = useState("");
  
    const [orderIdModal, setOrderIdModal] = useState("");
    const [orderNoModal, setOrderNoModal] = useState("");
    const [orderDateModal, setOrderDateModal] = useState("");
    const [orderStatusModal, setOrderStatusModal] = useState("");
    const [courierNameModal, setCourierNameModal] = useState("");
    const [lorryNameModal, setLorryNameModal] = useState("");

  const [courier1, setCourier1] = useState(""); 
  const [courier2, setCourier2] = useState(""); 
  const [courier3, setCourier3] = useState(""); 

  const [lorry1, setLorry1] = useState("");
  const [lorry2, setLorry2] = useState(""); 
  const [lorry3, setLorry3] = useState("");  

    const locationPass = useLocation();
      const navigate = useNavigate();

      const [adminEmail, setAdminEmail] = useState("");
      const [adminName, setAdminName] = useState("");
      const [productDatas, setProductDatas] = useState([]);

      const [submitCheck, setSubmitCheck] = useState(false);
      useEffect(() => { 
        const getUserData = async () => {
          await onAuthStateChanged(auth, (user) => { 
            if(user){
              onValue(ref(database, '/admin/' + auth.currentUser.uid), (snap) => {
              setAdminName(snap.val().adminName);
              setAdminEmail(snap.val().adminEmail);
          }); 
                }
                else {
                  window.location.href = '/'; 
                }
              })
         }
           getUserData(); 
      
      },[]);

      useEffect(() => { 
        const getSideData = async () => {
          let sidebar = document.querySelector(".sidebar");
          let sidebarBtn = document.querySelector(".sidebarBtn");
          sidebarBtn.onclick = function() {
            sidebar.classList.toggle("active");
            if(sidebar.classList.contains("active")){
            sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
          }else
            sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
          }
        }
         getSideData(); 
    
    },[]); 

    useEffect(() => { 
      const getUserData = async () => {
  
          const starCountRef = ref(database, 'products/');
          onValue(starCountRef, async snapshot => {
              let returnArray = [];
           await snapshot.forEach(element => {
              var item = {};
              item.productId = element.val().productId;
              item.category = element.val().category;
              item.brandName = element.val().brandName;
              item.itemName = element.val().itemName;
              item.size = element.val().size;
              item.pack = element.val().pack;
              item.composition = element.val().composition;
              item.yarnCount = element.val().yarnCount;
              item.priceWholesaler = element.val().priceWholesaler;
              item.priceRetailer = element.val().priceRetailer;
              item.pricePer = element.val().pricePer;
              item.productDescription = element.val().productDescription;
              item.status = element.val().status;
              item.coverImage = element.val().coverImage;
              item.sliderImage1 = element.val().sliderImage1;
              item.sliderImage2 = element.val().sliderImage2;
              item.sliderImage3 = element.val().sliderImage3;
              item.inStocks = element.val().inStocks;
              returnArray.push(item);
          });
       //   console.log(returnArray);
          setProductDatas(returnArray);
           
          });         
       }
         getUserData(); 
  
  },[]);

    useEffect(() => { 
      const getUserData = async () => {
        console.log(locationPass.state.customerIdPass);
return onValue(ref(database, '/customer/' + locationPass.state.customerIdPass), (snapshot) => {
  setCustomerId(snapshot.val().customerId);
  setAgentId(snapshot.val().agentId);
  setAgentName(snapshot.val().agentName);
  setShopName(snapshot.val().shopName);
  setCustomerType(snapshot.val().customerType);
    setCustomerName(snapshot.val().customerName);
    setAddressLine(snapshot.val().addressLine);
    setAddressLine1(snapshot.val().addressLine1);
    setCity(snapshot.val().city);
    setPinCode(snapshot.val().pinCode);
    setCommAddressLine(snapshot.val().commAddressLine);
    setCommAddressLine1(snapshot.val().commAddressLine1);
    setCommCity(snapshot.val().commCity);
    setCommPinCode(snapshot.val().commPinCode);
    setGstNumber(snapshot.val().gstNumber);
    setState(snapshot.val().state);
    setStateCode(snapshot.val().stateCode);
    setPhoneNumber(snapshot.val().phoneNumber);
    setSecondaryPhoneNumber(snapshot.val().secondaryPhoneNumber);
    setLandlineNumber(snapshot.val().landlineNumber);
   setShopImage(snapshot.val().shopImage);   
    setProfileImage(snapshot.val().profileImage); 
    setCourier1(snapshot.val().courier1 || ''); 
    setCourier2(snapshot.val().courier2 || ''); 
    setCourier3(snapshot.val().courier3 || ''); 
    setLorry1(snapshot.val().lorry1 || ''); 
    setLorry2(snapshot.val().lorry2 || ''); 
    setLorry3(snapshot.val().lorry3 || ''); 
});       
     }
       getUserData(); 

},[]); 

    useEffect(() => { 
        const getUserData = async () => {
          console.log(locationPass.state.customerIdPass);
            const starCountRef = ref(database, 'customer/' + locationPass.state.customerIdPass + '/orders' );
            onValue(starCountRef, async snapshot => {
              let returnArray = [];
              await snapshot.forEach(element => {       
                var item = {};
                console.log(element.val());
                item.customerId = element.val().customerId;
                item.customerName = element.val().customerName;
                item.customerType = element.val().customerType;
                item.gstNumber = element.val().gstNumber;
                item.orderAt = element.val().orderAt;
                item.orderId = element.val().orderId;
                item.orderNo = element.val().orderNo;
                item.orderStatus = element.val().orderStatus;
                item.paymentStatus = element.val().paymentStatus;
                item.phoneNumber = element.val().phoneNumber;
                item.shopName = element.val().shopName;
                item.products = element.val().products;
                item.coverImage = element.val().coverImage;
                item.courierName = element.val().courierName;
                item.lorryName = element.val().lorryName;
                returnArray.push(item);        
              });
              setDatas(returnArray);
            });         
         }
           getUserData(); 

    },[]); 


    let customerOrderss = (data, data1, data2, data3, data4, data5, data6) => {
      setDataOrders(data);
      setOrderIdModal(data1);
      setOrderDateModal(data2);
      setOrderStatusModal(data3);
      setCourierNameModal(data4);
      setLorryNameModal(data5);
      setOrderNoModal(data6);
    }
  
    let fileOrder = (data) => {
      setOrderIdFile(data);
      console.log(data);
      setFileOrders([]);
      let returnArray = [];
     const starCountRef = ref(database, 'orders/' + data + '/files');
      onValue(starCountRef, async element => {
        if(element.exists()){
          element.forEach(elements => {
          var item = {};
          item.fileId = elements.val().fileId;
          item.fileName = elements.val().fileName;
          item.fileNames = elements.val().fileNames;
          item.fileUpload = elements.val().fileUpload;
          returnArray.push(item); 
        });
        }
        else{
          console.log("No File");
        }
      }); 
      console.log(returnArray);
      setFileOrders(returnArray);
    }
  
    let handleFileChange = (e) => {
      e.preventDefault();
      let file = e.target.files[0];              
       let name = file.name;
       let ext = name.split('.').pop();
  let name1= fileName + '.' + ext;
       setFileNames(name1);
       const storageRef = storageRefs(storage, `files/${name}`);
       const uploadTask = uploadBytesResumable(storageRef, file);
   
       uploadTask.on("state_changed",
         (snapshot) => {
           const progresspercent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
           setProgress(progresspercent);
         },
         (error) => {
           console.log(error);
         },
         () => {
           getDownloadURL(storageRef).then((downloadURL) => {
            console.log(downloadURL);
             setFileUpload(downloadURL)
           });
         }
       );
  }
  
   let dateFormatss = (date) => {
    var data = moment.unix(date).format("DD-MM-YYYY");
    return data;
  }

  let dateFormatsss = (date) => {
    var data = moment.unix(date).format("DD-MM-YYYY HH:MM:SS");
    return data;
  }

  const percent = (gst, price, quantity) => {
    const percentage = (price * quantity) * (gst/100);
    return percentage;
   }

   const countTotalFinal = (gst, price, quantity) => {
    const percentage = (price * quantity) * (gst/100);
     const value = (price * quantity) + percentage;
     return value;
   }
  
  const countTotal = (data) => {
    var dataArr = data.reduce((acc, curr) => acc + curr.quantity * curr.price, 0);
   let value = data.reduce((acc, curr) => acc + curr.quantity * curr.price * (curr.gst/100) , 0); 
   var dataValue = dataArr + value;
  return dataValue;
  }
  
  const countTotals = (items) => items.reduce((acc, curr) => parseInt(acc) + parseInt(curr.quantity), 0);
  
  const multiply = (num1, num2) => {
    let result = num1 * num2;
    return result;
  }
  
  let handleStatus = (e) => {
    if(e.target.value == orderStatusModal){
      alert(`Order Status "${e.target.value}" is already updated.`);
        }
        else{
    if (window.confirm(`Are you sure want to change Order Status to ${e.target.value}?`)){
      if(e.target.value == "Packed"){
        Object.values(dataOrders).forEach(elements => {
          console.log(elements.productId);
          productDatas.filter(x => x.productId == elements.productId).map(element => {
          update(ref(database, 'products/' + elements.productId), {
       inStocks: parseInt(element.inStocks) - parseInt(elements.quantity)
       });
       });
         });
  
         update(ref(database, 'customer/' + e.target.id + '/orders/' + e.target.name), {
          orderStatus: e.target.value
          }).then(() => {
        update(ref(database, 'orders/' + e.target.name), {
        orderStatus: e.target.value
        }).then(() => {
        alert('Order details is updated successfully.');
        window.location.reload();
        });
        });
      }
      else if(e.target.value == "Cancelled"){
        let person = prompt("Please enter reason to cancel", "");
        console.log(person);
  update(ref(database, 'customer/' + e.target.id + '/orders/' + e.target.name), {
    orderStatus: e.target.value,
    cancelledReason: person,
    cancelledBy: "Admin",
    cancelledDate: moment().unix(),
    }).then(() => {
  update(ref(database, 'orders/' + e.target.name), {
  orderStatus: e.target.value,
  cancelledReason: person,
  cancelledBy: "Admin",
  cancelledDate: moment().unix(),
  }).then(() => {
  alert('Order details is updated successfully.');
  window.location.reload();
  });
  });
      }
      else{
        update(ref(database, 'customer/' + e.target.id + '/orders/' + e.target.name), {
          orderStatus: e.target.value
          }).then(() => {
        update(ref(database, 'orders/' + e.target.name), {
        orderStatus: e.target.value
        }).then(() => {
        alert('Order details is updated successfully.');
        window.location.reload();
        });
        });
            }
    }
    else{
      return false;
    }
  }
  }
  
  let handlePaymentStatus = (e) => {
    if (window.confirm(`Are you sure want to change Payment Status to ${e.target.value}?`)){
    update(ref(database, 'customer/' + locationPass.state.customerIdPass + '/orders/' + e.target.name), {
      paymentStatus: e.target.value
      })
    
    update(ref(database, 'orders/' + e.target.name), {
    paymentStatus: e.target.value
    }).then(() => {
    alert('Order details is updated successfully.');
    window.location.reload();
    });
  }
  else{
    return false;
  }
    }
  
    let submit = e => {
      e.preventDefault();
      setSubmitCheck(true);
      if(fileName == ""){
        alert("Please enter file name.");
        setSubmitCheck(false);
      }
    else{
      const newPostKey = push(child(ref(database), 'orders/' + orderIdFile + '/files')).key;
    
      const postData = {
        fileName: fileName,
        fileNames: fileNames,
        fileUpload: fileUpload,
        fileId: newPostKey,
        uploadedAt: moment().unix()
      };
    
      const updates = {};
      updates[`/orders/${orderIdFile}/files/` + newPostKey] = postData;
      updates[`/customer/${locationPass.state.customerIdPass}/orders/${orderIdFile}/files/` + newPostKey] = postData;
    
      return update(ref(database), updates).then(() => {
        alert('File is uploaded successfully.');
        setSubmitCheck(false);
        handleFileChange(orderIdFile, locationPass.state.customerIdPass);
        setFileName("");
        setFileNames("");
        setProgress(0);
        setFileUpload(""); 
          });
    }
  }
  
  let reset = e => {
      e.preventDefault();
      setFileName("");
    setFileNames("");
    setProgress(0);
    setFileUpload("");
  }
  
  const print = (data, data1) => {
    var divContents = document.getElementById("printarea").innerHTML; 
    var a = window.open('Orders', 'Order', 'height=500, width=500'); 
    a.document.write(`<html><head><title>${data} - Order List ${dateFormatsss(data1)}</title>`); 
    a.document.write('</head><body>'); 
    a.document.write(divContents); 
    a.document.write('</body>'); 
    a.document.write('</html>'); 
    a.document.close(); 
  
    a.focus();
    a.print();
    a.close();
  }

  const clickEventListener = (data) => {
    console.log(data);
    remove(ref(database, `/customer/${customerId}/orders/${orderIdFile}/files/` + data))
    remove(ref(database, `/orders/${orderIdFile}/files/` + data)).then(() => {
      fileOrder(orderIdFile, customerId);
     // alert('File is deleted successfully.');
    });
    }

  let logOut = e => {
    signOut(auth);
     window.location.href = '/';
     }

     const quantityCheck = (data, data1) => {
      let preview;
      if(data <= data1){
      preview = (<h6 style={{color: "red", fontSize: '12px', whiteSpace: 'nowrap'}}>{data}</h6>);
      }
      else{
        preview = (<h6 style={{color: "green", fontSize: '12px', whiteSpace: 'nowrap'}}>{data}</h6>);
      }
      return preview;
          }

          const countTotalsss = (items) => items.reduce((acc, curr) => acc + curr.quantity * curr.price, 0);

          const countTotalss = (data) => {
            let value = data.reduce((acc, curr) => acc + ((curr.quantity * curr.price) * (curr.gst/100)) , 0); 
           return value;
           }
       
           const countTotalFinals = (data) => {
             var dataArr = data.reduce((acc, curr) => acc + curr.quantity * curr.price, 0);
            let value = data.reduce((acc, curr) => acc + curr.quantity * curr.price * (curr.gst/100) , 0); 
            var dataValue = dataArr + value;
           return dataValue;
           }

           let handleCourier = (e) =>{
            if (window.confirm(`Are you sure want to change Courier Name to ${e.target.value}?`)){
            update(ref(database, 'customer/' + customerId + '/orders/' + orderIdModal), {
             courierName: e.target.value
              }).then(() => {
            update(ref(database, 'orders/' + orderIdModal), {
            courierName: e.target.value
            }).then(() => {
            alert('Courier details is updated successfully.');
           // window.location.reload();
            });
            });
          }
          else{
            return false;
          }
          }
          
          let handleLorry = (e) =>{
            if (window.confirm(`Are you sure want to change Lorry Name to ${e.target.value}?`)){
            update(ref(database, 'customer/' + customerId + '/orders/' + orderIdModal), {
             lorryName: e.target.value
              }).then(() => {
            update(ref(database, 'orders/' + orderIdModal), {
            lorryName: e.target.value
            }).then(() => {
            alert('Lorry details is updated successfully.');
           // window.location.reload();
            });
            });
          }
          else{
            return false;
          }
          }

    return (
      <section>
      <div className="sidebar">
     
      <div className="logo-details">&nbsp;
      <img src={require("./images/logoF.png")} style={{width: '50px', height: '50px'}} alt=""/> &nbsp;
      <img src={require("./images/elge.png")} style={{width: '150px', height: '80px'}} alt=""/>
      </div>
        <ul className="nav-links">
          <li>
          <Link to={"/dashboard"}>
            <a>
              <i className='bx bx-grid-alt' ></i>
              <span className="links_name">Dashboard</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/category"}>
            <a>
              <i className='bx bx-align-justify' ></i>
              <span className="links_name">Manage Category</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/brand"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Brand</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/pack"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Manage Pack</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/manageAgents"}>
            <a>
              <i className='bx bx-user-pin' ></i>
              <span className="links_name">Manage Agents</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/viewProduct"}>
            <a>
              <i className='bx bx-box' ></i>
              <span className="links_name">Manage Product</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/createProduct"}>
            <a>
            <i className='bx bx-pie-chart-alt-2' ></i>
              <span className="links_name">Create Product</span>
            </a>
            </Link>
          </li>
          <li>
            <a className="active">
              <i className='bx bx-user' ></i>
              <span className="links_name">Manage Customer</span>
            </a>
          </li>
          <li>
          <Link to={"/createCustomer"}>
            <a>
            <i className='bx bx-user-plus' ></i>
              <span className="links_name">Create Customer</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/orders"}>
            <a>
              <i className='bx bx-list-ul' ></i>
              <span className="links_name">Orders</span>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/settings"}>
            <a>
              <i className='bx bx-cog' ></i>
              <span className="links_name">Settings</span>
            </a>
            </Link>
          </li>
          <li className="log_out">
          <a onClick={logOut}>
              <i className='bx bx-log-out'></i>
              <span className="links_name">Log out</span>
            </a>
          </li>
        </ul>
    </div>
    <section className="home-section">
      <nav>
        <div className="sidebar-button">
          <i className='bx bx-menu sidebarBtn'></i>
          <span className="dashboard">Customer Order</span>
        </div>

        <div className="profile-details">
        <img src={require("./images/male.png")} style={{width: '50px', height: '50px'}} alt=""/>
          <span className="admin_name">Admin</span>
          <i className='bx bx-chevron-down' ></i>
        </div>
      </nav>
  
      <div className="home-content">
      <div className='search_result_list my-2'>
      <button className="btn btn-primary ml-2 mb-2" style={{borderRadius:'5px'}} onClick={() => navigate("/manageCustomer")}>Back</button>
              
      <div className="table-responsive mb-2" style={{borderRadius: '10px',border:'0.5px solid #ccc',borderCollapse: 'collapse'}}>
         <table className='table table-borderless mb-0' style={{border:'none', background: "white"}}>
         <thead className="text-center" style={{backgroundColor:'#e7f4fe', color:'grey'}}>
           <tr>
           <th style={{fontSize: '16px'}}>Order Date</th>
             <th style={{fontSize: '16px'}}>Order Id</th>
             <th style={{fontSize: '16px'}}>Products</th>
             <th style={{fontSize: '16px'}}>Total Price</th>
             <th style={{fontSize: '16px'}}>Order Status</th>
             <th style={{fontSize: '16px'}}>Payment Status</th>
             <th style={{fontSize: '16px'}}>Action</th>
           </tr>
           </thead>
           <tbody>
            {Object.values(datas).reverse().map((persons,index) =>
      
           <tr>
               <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{dateFormatss(persons.orderAt)}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.orderId}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{Object.values(persons.products).length}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>₹{countTotal(Object.values(persons.products))}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.orderStatus} </td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>
              {persons.paymentStatus == 'Pending'?
                                               <select className="form-control" onClick={handlePaymentStatus} name={persons.orderId}>
                                               <option value={persons.paymentStatus}>{persons.paymentStatus}</option>
                                               <option value="Paid">Paid</option>
               <option value="Failed">Failed</option>
               <option value="Refunded">Refunded</option>
               </select>
               :
              null
               }
               {persons.paymentStatus == 'Paid'?
                                        <span style={{color: 'green'}}>Paid</span>                                        
               :
              null
               }
                              {persons.paymentStatus == 'Failed'?
                                        <span style={{color: 'red'}}>Failed</span>                                        
               :
              null
               }
                                             {persons.paymentStatus == 'Refunded'?
                                        <span style={{color: 'blue'}}>Refunded</span>                                        
               :
              null
               }
               </td>
             <td style={{verticalAlign: 'middle'}}>
              <i className="bx bx-show" data-toggle="modal" data-target="#ordersData" onClick={() => customerOrderss(persons.products, persons.orderId, persons.orderAt, persons.orderStatus, persons.courierName, persons.lorryName, persons.orderNo)} style={{fontSize: '24px'}} aria-hidden="true"></i>
             {/*} &emsp;<i className="bx bx-edit" style={{fontSize: '24px'}} aria-hidden="true"></i> */}
              &emsp;<i className="bx bx-file" data-toggle="modal" data-target="#fileData" onClick={() => fileOrder(persons.orderId)} style={{fontSize: '24px'}} aria-hidden="true"></i> 
                     </td>
           </tr>
          )}
</tbody>
</table>
       </div>
       </div>

       <div className="modal col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto" id="ordersData">
              <div className="modal-dialog" style={{maxWidth: '80%'}}>
              <div className="modal-content">

              <div className="modal-header">
                <h5 className="modal-title text-center">Order Details</h5>
                <button className="close" data-dismiss="modal" style={{color: 'black'}}>X</button>
              </div>

              <br></br>
<div style={{flexDirection: "row"}}>
              <div style={{float: 'left'}}>
                <h6 className="text-left">&emsp;Order No: {orderNoModal}</h6>
                </div>
              <div style={{float: 'right'}}>
                <h6 className="text-right">{dateFormatsss(orderDateModal)}&emsp;</h6>
                </div>
                </div>

              <div className="modal-body">
              <div className="table-responsive mb-2" style={{borderRadius: '10px',border:'0.5px solid #ccc',borderCollapse: 'collapse'}}>
         <table className='table table-borderless mb-0' style={{border:'none', background: "white"}}>
         <thead className="text-center" style={{backgroundColor:'#e7f4fe', color:'grey'}}>
           <tr>
             <th style={{fontSize: '16px'}}>Image</th>
             <th style={{fontSize: '16px'}}>Brand</th>  
             <th style={{fontSize: '16px'}}>Product Name</th>
             <th style={{fontSize: '16px'}}>Category</th>    
           <th style={{fontSize: '16px'}}>Size</th>
             <th style={{fontSize: '16px'}}>Pack</th>
             <th style={{fontSize: '16px'}}>Price</th>
             <th style={{fontSize: '14px'}}>Stocks</th>
             <th style={{fontSize: '16px'}}>Quantity</th>
             <th style={{fontSize: '14px'}}>Total</th>
             <th style={{fontSize: '14px'}}>GST</th>
             <th style={{fontSize: '16px'}}>Grand Total</th>
           </tr>
           </thead>
           <tbody>
            {Object.values(dataOrders).map((persons,index) =>
      
           <tr>
             <td> <img src={persons.coverImage} style={{borderRadius:'5px',width:'60%', height: '7vh', border:'2px solid #ccc', objectFit: 'fill'}}/></td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.brandName}</td>  
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.itemName}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.category}</td>       
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.size}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.pack}</td>  
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>₹{persons.price}/{persons.pricePer}</td>
             <td style={{verticalAlign: 'middle', fontSize: '12px'}}>{productDatas.filter(x => x.productId == persons.productId).map((person,index) => <h6 key={index}>{quantityCheck(person.inStocks, persons.quantity)}</h6> )}</td>   
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.quantity}</td>   
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>₹{multiply(persons.price, persons.quantity)}</td>  
             <td style={{verticalAlign: 'middle', fontSize: '12px'}}>₹{percent(persons.gst, persons.price, persons.quantity)} ({persons.gst} %)</td>        
             <td style={{verticalAlign: 'middle', fontSize: '12px'}}>₹{countTotalFinal(persons.gst, persons.price, persons.quantity)}</td>        
           </tr>
          )}
          <tr>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td style={{verticalAlign: 'middle', fontSize: '14px', whiteSpace: 'nowrap'}}>Total</td>
<td style={{verticalAlign: 'middle', fontSize: '14px', whiteSpace: 'nowrap', color: '#1e40e9'}}>{countTotals(Object.values(dataOrders))}</td>
<td style={{verticalAlign: 'middle', fontSize: '14px', whiteSpace: 'nowrap', color: '#1e40e9'}}>₹{countTotalsss(Object.values(dataOrders))}</td>
<td style={{verticalAlign: 'middle', fontSize: '14px', whiteSpace: 'nowrap', color: '#1e40e9'}}>₹{countTotalss(Object.values(dataOrders))}</td>
<td style={{verticalAlign: 'middle', fontSize: '14px', whiteSpace: 'nowrap', color: '#1e40e9'}}>₹{countTotalFinals(Object.values(dataOrders))}</td>
</tr>
</tbody>
</table>
</div>

<div className='row'>
  <div className='col-12'>
<h6 style={{fontSize: '14px', whiteSpace: 'nowrap'}}>Billing Address: {addressLine}, {addressLine1}, {city}, {state}, {pinCode}</h6>
<h6 style={{fontSize: '14px', whiteSpace: 'nowrap'}}>Shipping Address: {commAddressLine}, {commAddressLine1}, {commCity}, {state}, {commPinCode}</h6>
</div>
<div className='col-12'>            
            {/*} <h6 style={{fontSize: '14px'}}>Courier Name: {courierNameModal}</h6>
             <h6 style={{fontSize: '14px'}}>Lorry Name: {lorryNameModal}</h6>  */}
             <div className='row'>
             <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
          <label style={{fontSize: '14px'}}>Courier Name</label>
                                               <select style={{fontSize: '12px'}} className="form-control" onClick={handleCourier}>
                                               <option value={courierNameModal}>{courierNameModal}</option>        
                                               {courier1 == ""?
        null
        :
        <option value={courier1}>{courier1}</option>   
}
{courier2 == ""?
        null
        :
        <option value={courier2}>{courier2}</option>   
}
{courier3 == ""?
        null
        :
        <option value={courier3}>{courier3}</option>   
}                              
               </select>
               </div>
               <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
          <label style={{fontSize: '14px'}}>Lorry Name</label>
                                               <select style={{fontSize: '12px'}} className="form-control" onClick={handleLorry}>
                                               <option value={lorryNameModal}>{lorryNameModal}</option>        
                                               {lorry1 == ""?
        null
        :
        <option value={lorry1}>{lorry1}</option>   
}
{lorry2 == ""?
        null
        :
        <option value={lorry2}>{lorry2}</option>   
}
{lorry3 == ""?
        null
        :
        <option value={lorry3}>{lorry3}</option>   
}                              
               </select>
               </div>
               <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">  
          {orderStatusModal == 'Pending'?
          <div style={{fontSize: '12px', whiteSpace: 'nowrap'}}>
          <label>Order Status</label>
                                               <select style={{fontSize: '12px'}} className="form-control" onClick={handleStatus} name={orderIdModal} id={customerId}>
                                               <option value={orderStatusModal}>{orderStatusModal}</option>
                                               <option value="Confirmed">Confirmed</option>
               <option value="Packed" disabled>Packed</option>
               <option value="Dispatched" disabled>Dispatched</option>
               <option value="Delivered" disabled>Delivered</option>
               <option value="Cancelled">Cancelled</option>
               </select>
               </div>
               :
              null
               }
               {orderStatusModal == 'Confirmed'?
                         <div style={{fontSize: '12px', whiteSpace: 'nowrap'}}>
                         <label>Order Status</label>
                                               <select className="form-control" onClick={handleStatus} name={orderIdModal} id={customerId}>
                                               <option value={orderStatusModal}>{orderStatusModal}</option>
               <option value="Packed">Packed</option>
               <option value="Dispatched" disabled>Dispatched</option>
               <option value="Delivered" disabled>Delivered</option>
               </select>
               </div>
               :
              null
               }
                              {orderStatusModal == 'Packed'?
                                        <div style={{fontSize: '12px', whiteSpace: 'nowrap'}}>
                                        <label>Order Status</label>
                                               <select className="form-control" onClick={handleStatus} name={orderIdModal} id={customerId}>
                                               <option value={orderStatusModal}>{orderStatusModal}</option>
               <option value="Dispatched">Dispatched</option>
               <option value="Delivered" disabled>Delivered</option>
               </select>
               </div>
               :
              null
               }
               {orderStatusModal == 'Dispatched'?
                         <div style={{fontSize: '12px', whiteSpace: 'nowrap'}}>
                         <label>Order Status</label>
                                               <select className="form-control" onClick={handleStatus} name={orderIdModal} id={customerId}>
                                               <option value={orderStatusModal}>{orderStatusModal}</option>
               <option value="Delivered">Delivered</option>
               </select>
               </div>
               :
              null
               }

{orderStatusModal == 'Delivered'?
<>Order Status: Delivered</>
               :
              null
               }     

{orderStatusModal == 'Cancelled'?
<>Order Status: Cancelled</>
               :
              null
               }
 </div>
               </div>
             </div> 
             </div>
          </div>

          <div className="modal-footer">
                                    <button type="button" className="btn btn-sm" onClick={() => print(orderIdModal, orderDateModal)} style={{backgroundColor: '#263579', color: '#fff', letterSpacing: '.3px',fontSize:'16px'}}>Print</button>
                  </div>

                            </div>
                   
              </div>
              </div>

              <div className="modal col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto" id="fileData">
              <div className="modal-dialog" style={{maxWidth: '70%'}}>
              <div className="modal-content">

              <div className="modal-header">
                <h5 className="modal-title text-center">Order File Upload</h5>
                <button className="close" data-dismiss="modal" style={{color: 'black'}}>X</button>
              </div>

              <div className="modal-body">

              <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>FILE NAME </label>
       
      <input type="text" className="form-control" id="fileName" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      placeholder="File Name" name="fileName" value={fileName} 
      onChange={(event) => { setFileName( event.target.value ); }} required/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>FILE</label>
       
      <input type="file" className="form-control" id="files" style={{borderRadius:'10px', textIndent:'10px', fontSize: '12px', height: '45px'}} 
      name="files" onChange={handleFileChange} required/>
      
      </div>

      </div>


      <div className="superadmincreatedoc_savebtn" style={{textAlign:'center', marginTop:'10px'}}>
      {submitCheck == false?
                                  <>
                   <button className="btn btn-primary" style={{borderRadius:'5px'}} onClick={submit}>Save</button>&emsp;
                        <button className="btn btn-secondary ml-2" style={{borderRadius:'5px'}} onClick={reset}>Reset</button>
                        </>
                        :
                        <center>
                        <div className="loader"></div>
                        </center>
              } 
                   </div>

<br></br>
                   <div className="table-responsive mb-2" style={{borderRadius: '10px',border:'0.5px solid #ccc',borderCollapse: 'collapse'}}>
         <table className='table table-borderless mb-0' style={{border:'none', background: "white"}}>
         <thead className="text-center" style={{backgroundColor:'#e7f4fe', color:'grey'}}>
           <tr>
           <th style={{fontSize: '16px'}}>File Name</th>
             <th style={{fontSize: '16px'}}>File Upload</th>   
             <th style={{fontSize: '16px'}}>Action</th>    
           </tr>
           </thead>
           <tbody>
           {Object.values(fileOrders).length == 0?
           <tr>
            <td></td>
            <td style={{verticalAlign: 'middle', fontSize: '14px', alignContent: "center"}}>No Data</td>
            <td></td>
           </tr>
           :
<>
           {Object.values(fileOrders).map((personss,index) =>
      
      <tr key={index}>
      <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{personss.fileName}</td>         
    <td style={{verticalAlign: 'middle', fontSize: '14px', color: "#1e40e9"}}><a href={personss.fileUpload} target="_blank">{personss.fileNames}</a></td>
    <td><i className="bx bx-trash" onClick={()=> clickEventListener(personss.fileId)} style={{fontSize: '24px'}} aria-hidden="true"></i> 
           </td>
            </tr>
          )}
          </>
        }
</tbody>
</table>
</div>

          </div>

          <div className="modal-footer">
                                     </div>

                            </div>
                   
              </div>
              </div>

              <div id='printarea' style={{display: 'none'}}>
              <div style={{height: '99%', width: '99%'}}>
              <h5 className="modal-title text-center">{orderIdModal} - Order Details</h5>
              <div className="mb-2">
         <table className='mb-0' style={{background: "white", border: '1px solid black', width: '100%', borderCollapse: 'collapse', borderRadius: '10px'}}>
         <thead className="text-center" style={{background: "white"}}>
           <tr>
           <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>Brand</th>  
             <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>Product Name</th>
             <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>Category</th>    
           <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>Size</th>
             <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>Pack</th>
             <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>Price</th>
             <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>Quantity</th>
             <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>Total</th>
             <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>GST</th>
             <th style={{fontSize: '14px', border: '1px solid black', borderCollapse: "collapse"}}>Grand Total</th>
           </tr>
           </thead>
           <tbody style={{background: "white"}}>
            {Object.values(dataOrders).map((persons,index) =>
      
           <tr style={{background: "white"}}>
                         <td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>{persons.brandName}</td>   
             <td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>{persons.itemName}</td>
             <td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>{persons.category}</td>      
             <td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>{persons.size}</td>
             <td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>{persons.pack}</td>  
             <td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>₹{persons.price}/{persons.pricePer}</td>
             <td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>{persons.quantity}</td>   
             <td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>₹{multiply(persons.price, persons.quantity)}</td>        
             <td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>₹{percent(persons.gst, persons.price, persons.quantity)} ({persons.gst} %)</td>        
             <td style={{verticalAlign: 'middle', fontSize: '12px', border: '1px solid black', borderCollapse: "collapse"}}>₹{countTotalFinal(persons.gst, persons.price, persons.quantity)}</td>              
           </tr>
          )}
</tbody>
</table>
</div>
<br></br>
<div className='row'>
<div className='col-12'>     
<h6 style={{fontSize: '14px'}}>Order No: {orderNoModal}</h6>        
             <h6 style={{fontSize: '14px'}}>Courier Name: {courierNameModal}</h6> 
             <h6 style={{fontSize: '14px'}}>Lorry Name: {lorryNameModal}</h6> 
             </div> 
             </div>
<div style={{float: 'right'}}>

          <h6 style={{fontSize: '14px', marginRight: '30px', display: 'inline'}}>Total Quantity:  {countTotals(Object.values(dataOrders))}</h6>
                                    <h6 style={{fontSize: '14px', marginRight: '30px', display: 'inline'}}>Total Price:  ₹{countTotal(Object.values(dataOrders))}</h6>
                                                     
                                                      </div>
              </div>
              </div>

          </div>

        </section> 
</section>
        );

    }
    export default CustomerOrder;