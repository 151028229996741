import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage, ref } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';

var config = {
  apiKey: "AIzaSyC42g6k4so7YwNmHizrttZFrJF8I92v_xw",
  authDomain: "elgeplusapps.firebaseapp.com",
  databaseURL: "https://elgeplusapps-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "elgeplusapps",
  storageBucket: "elgeplusapps.appspot.com",
  messagingSenderId: "833382553596",
  appId: "1:833382553596:web:cb84aab5dfc5b81510c479",
  measurementId: "G-95K7812SGY"
};

  const app = initializeApp(config);

const db = getFirestore(app);
    const database = getDatabase(app);
    const storage = getStorage(app);
    const auth = getAuth(app);
    const storageRefs = ref;
    export {auth, database, storage, storageRefs, db};
 
